import Blogs from "../components/Blogs";
import CompanyProfile from "../components/CompanyProfile";
import EditBlog from "../components/EditBlog";
import EditCompanyProfile from "../components/EditCompanyProfile";
import EditMainportfolio from "../components/EditMainportfolio";
import EditProfile from "../components/EditProfile";
import EditSlide from "../components/EditSlide";
import GetSlider from "../components/GetSlider";
import MainPortfolio from "../components/MainPortfolio";
import NewBlog from "../components/NewBlog";
import Profile from "../components/Profile";
import AdminPage from "../components/subcomponents/AdminPage";
import Slider from "../components/subcomponents/Slider";
import CreateService from "../components/subcomponents/AdminCreate/createService";
import UpdateFeedback from "../components/subcomponents/AdminCreate/UpdateFeedback";
import AddFeedback from "../components/subcomponents/AdminCreate/AddFeedback";
import AddSubPortfolio from "../components/subcomponents/AdminCreate/AddsubPortfolio";
import UpdateSubportfolio from "../components/subcomponents/AdminCreate/UpdateSubportfolio";
import Subportfolio from "../components/subcomponents/AdminCreate/Subportfolio";
import UpdateService from "../components/subcomponents/AdminCreate/UpdateService";
import AddService from "../components/subcomponents/AdminCreate/AddService";
import Feedbacks from "../components/Feedbacks";
import Dashbord from "../components/Dashbord";
import Feedback from "../components/subcomponents/AdminCreate/Feedbacks";
import PostPage from "../components/PostPage";
const Routes = [
    {
        path: "/slider",
        element: <Slider />
    },
    {
        path: "/editslider/:id",
        element: <EditSlide />
    },
    {
        path: "/adminpage",
        element: <AdminPage />
    },
    {
        path: "/mainportfolio",
        element: <MainPortfolio />
    },
    {
        path: "/editmainportfolio/:id",
        element: <EditMainportfolio />
    },
    {
        path: "/blogpage",
        element: <Blogs />
    },
    {
        path: "/newblog",
        element: <NewBlog />
    },
    {
        path: "/editblog/:id",
        element: <EditBlog />
    },
    {
        path: "/companyprofile",
        element: <CompanyProfile />
    },
    {
        path: "/editcompany/:id",
        element: <EditCompanyProfile />
    },
    {
        path: "/userprofile",
        element: <Profile />
    },
    {
        path: "/editprofile/:id",
        element: <EditProfile />
    },
    {
        path: "/create-service",
        element: <CreateService />
    },
    {
        path: "/adminpage/create-service",
        element: <CreateService />
    },
    {
        path: "/adminpage/add-service",
        element: <AddService />
    },
    {
        path: "/adminpage/update-service/:id",
        element: <UpdateService />
    },
    {
        path: "/adminpage/subportfolio",
        element: <Subportfolio />
    },
    {
        path: "/adminpage/update-subportfolio/:id",
        element: <UpdateSubportfolio />
    },
    {
        path: "/adminpage/add-subportfolio",
        element: <AddSubPortfolio />
    },
    {
        path: '/adminpage/feedbacks',
        element: <Feedback />
    },
    {
        path: 'adminpage/add-feedback',
        element: <AddFeedback />
    },
    {
        path: "/adminpage/update-feedback/:id",
        element: <UpdateFeedback />
    },
    {
        path: "/dashbord",
        element: <Dashbord />
    },


]

export default Routes