import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'
import axios from 'axios'

const useGetSlider = () => {
    const [data, setSliderData] = useState([])
    const getSlider = async () => {
        try {
            const res = await axios.get(BASE_URL + 'allsliders', {
                withCredentials: true
            })
            const data = res?.data?.data
            setSliderData(data)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getSlider()
    }, [])
    return { data }
}

export default useGetSlider
