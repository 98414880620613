import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'

const useGetMainportfolio = () => {
    const [mainportfolioData, setMainportfolioData] = useState([])

    const handleGetMainPortfolio = async () => {
        try {
            const res = await fetch(BASE_URL + "getmainportfolio")
            const json = await res.json()
            const data = json?.data
            setMainportfolioData(data)
            // console.log("mainportfilio", data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetMainPortfolio()
    }, [])

    return { mainportfolioData }
}

export default useGetMainportfolio
