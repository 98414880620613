import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaEyeSlash } from "react-icons/fa";

const Register = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .min(4, 'First name must be at least 4 characters long.')
            .max(50, 'First name must be no longer than 50 characters.')
            .required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        emailId: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Password must be at least 8 characters long and contain uppercase, lowercase, numbers, and symbols.'
            ),
    });

    const handleSubmit = async (values, { setSubmitting, setStatus }) => {
        setStatus({ error: null, success: null });

        try {
            const response = await fetch('http://localhost:3511/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            });

            const data = await response.json();

            if (response.ok) {
                setStatus({ success: 'Registration successful! You can now log in.' });
                setTimeout(() => navigate('/login'), 1000); // Brief delay before navigation
            } else {
                setStatus({ error: data.message || 'Registration failed.' });
            }
        } catch (err) {
            console.error(err);
            setStatus({ error: 'An error occurred. Please try again later.' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={{ firstName: '', lastName: '', emailId: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, status }) => (
                <Form>
                    <div className="flex justify-center mt-20 px-4 sm:px-0">
                        <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                            <div className="text-center pb-3">
                                <p className="text-2xl font-semibold text-gray-700 mb-2">Sign Up</p>
                            </div>

                            {status?.error && <p className="text-red-500 text-center mb-4">{status.error}</p>}
                            {status?.success && <p className="text-green-500 text-center mb-4">{status.success}</p>}

                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">First Name</p>
                                <Field
                                    type="text"
                                    name="firstName"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    placeholder="Enter First Name"
                                />
                                <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>

                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Last Name</p>
                                <Field
                                    type="text"
                                    name="lastName"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    placeholder="Enter Last Name"
                                />
                                <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>

                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Email</p>
                                <div className="relative">
                                    <Field
                                        type="email"
                                        name="emailId"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 pl-10 outline-none text-gray-600"
                                        placeholder="Enter Email"
                                    />
                                    <div className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-5 w-5">
                                            <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                                            <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                                        </svg>
                                    </div>
                                    <ErrorMessage name="emailId" component="div" className="text-red-500 text-sm mt-1" />
                                </div>
                            </div>

                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Password</p>
                                <div className="relative">
                                    <Field
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                        placeholder="Enter Password"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                        aria-label="Toggle password visibility"
                                        className="absolute inset-y-0 right-3 flex items-center text-gray-600"
                                    >
                                        {showPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 16 16">
                                                <path d="M8 3a7 7 0 0 0-6.657 4.168.5.5 0 0 0 0 .664A7 7 0 0 0 8 13a7 7 0 0 0 6.657-4.168.5.5 0 0 0 0-.664A7 7 0 0 0 8 3zm0 1a6 6 0 0 1 5.197 3.32c-.918.614-2.375 1.68-5.197 1.68-2.822 0-4.279-1.066-5.197-1.68A6 6 0 0 1 8 4z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM8 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg>
                                        ) : (
                                            <FaEyeSlash />
                                        )}
                                    </button>
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                                </div>
                            </div>

                            <div className="mb-4 text-center">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className=" py-2 px-4 bg-blue-950 text-white font-semibold rounded-lg shadow-md focus:outline-none"
                                >
                                    {isSubmitting ? 'Submitting...' : 'Register'}
                                </button>
                            </div>

                            <div className="text-center">
                                <p className="text-sm">
                                    Already have an account?{' '}
                                    <Link to="/login" className="text-blue-500 hover:underline">
                                        Login
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Register;
