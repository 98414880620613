import React, { useEffect, useState } from 'react'
import { BASE_URL, IMAGE_BASE_URL } from '../utils/Helper'
import Pageination from './subcomponents/Pageination'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const BlogCard = ({ data, page, setPAge }) => {
    const [pop, setPop] = useState(false)
    const [idToDelete, setIdToDelete] = useState("")

    const location = useLocation()

    const navigate = useNavigate()
    const handleDelete = (id) => {
        console.log("id", id)
        if (id) {
            setPop(true)
            setIdToDelete(id)
        }
    }


    const confirmDelete = async () => {
        // console.log("id to be deleted", idToDelete)

        try {
            const res = await axios.delete(`${BASE_URL}deleteblog/${idToDelete}`, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });
            console.log(res);
            setPop(false)
            navigate('/blogpage');
            window.location.reload()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="p-4 bg-white rounded-lg shadow-md mt-3">

                {pop && (
                    <>
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
                                    Are you sure you want to delete this blog?
                                </h2>
                                <p className="text-center text-gray-600 mb-6">
                                    This action cannot be undone.
                                </p>
                                <div className="flex justify-center space-x-4">
                                    <button
                                        onClick={confirmDelete}
                                        className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        onClick={() => setPop(false)}
                                        className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <p className="text-xl font-semibold text-gray-800 mb-2">{data?.serviceName}</p>
                <p className="text-lg font-medium text-gray-700 mb-1">{data?.title}</p>
                <p className="text-gray-600 mb-4">{data?.subtitle}</p>

                <div className="flex flex-wrap gap-4">
                    {data?.images?.length > 0 && data?.images?.map((item, index) => (
                        <img
                            key={index}
                            src={IMAGE_BASE_URL + item?.url}
                            alt="BlogImage"
                            className="w-1/3 h-auto rounded-md object-cover"
                        />
                    ))}
                </div>
                <p className="text-gray-600 mb-4 mt-2">{data?.content}</p>
                <div className='flex justify-end'>
                    <Link to={`/editblog/${data?._id}`} state={data} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain rounded-xl'>
                        Edit
                    </Link>
                    <button className='border bg-red-800 text-white px-4 hover:bg-white hover:text-textmain rounded-xl' onClick={() => handleDelete(data?._id)}>
                        Delete
                    </button>
                </div>
            </div>

        </>


    )
}

export default BlogCard
