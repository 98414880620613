import React from 'react';
import useGetMainportfolio from '../customhooks/useGetMainportfolio';
import { IMAGE_BASE_URL } from '../utils/Helper';
import { Link } from 'react-router-dom';

const MainPortfolio = () => {
    const { mainportfolioData } = useGetMainportfolio();
    console.log("mainportfolioData", mainportfolioData);

    if (!mainportfolioData) return <h1 className="text-center mt-10">Loading...</h1>;

    return (
        <div className="container mx-auto px-4 py-10">

            <div className="">
                {mainportfolioData.map((item) => (
                    <div key={item._id} className="">
                        <div className='flex justify-between mb-2'>
                            <h3 className="text-xl font-semibold ">{item.title}</h3>
                            <Link to={`/editmainportfolio/${item._id}`} state={item} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain rounded-xl'>
                                Edit
                            </Link>
                        </div>
                        <p className="text-gray-600 mb-4">{item.subTitle}</p>

                        {item.image && item.image.length > 0 && (
                            <div className="grid grid-cols-2 gap-2">
                                {item.image.map((image, index) => (
                                    <img
                                        key={index}
                                        src={`${IMAGE_BASE_URL}${image}`}
                                        alt="MainPortfolio"
                                        className="rounded-lg object-cover h-80 w-full"
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MainPortfolio;
