import React from 'react'
import { serviceName } from '../../utils/constants'

const ServiceCoursal = () => {

    console.log("serviceName", serviceName)
    return (
        <div className="carousel rounded-box">
            {serviceName && serviceName.length > 0 ? (
                <div className="carousel-item whitespace-nowrap overflow-x-auto">
                    <h1 className='text-white text-lg md:text-xl font-semibold p-3 flex items-center'>
                        {serviceName.map(data => (
                            <span key={data.name} className='flex flex-col justify-center items-center gap-3 border-r-2 px-3 md:px-7 '>
                                <span
                                // className='svg-icon'
                                // dangerouslySetInnerHTML={{ __html: data.svg }}
                                // style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                />
                                <img src={data?.svg} className='w-20 rounded-full' alt="" />
                                <p className='border border-white rounded-xl p-2 hover:border-white hover:cursor-pointer'>{data.name}</p>
                            </span>
                        )).reduce((prev, curr) => [prev, " ", curr])}
                    </h1>
                </div>
            ) : (
                <h1 className='text-white text-center'>No services available</h1>
            )}
        </div>
    )
}

export default ServiceCoursal
