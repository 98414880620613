import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Profile = () => {
    const user = useSelector((store) => store.user.user);

    return (
        <div className="flex justify-center items-center min-h-screen ">
            <div className="w-full max-w-md bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-700 text-center mb-4">
                    User Profile
                </h2>
                <div className="flex flex-col items-center">
                    <div className="w-24 h-24 bg-blue-500 text-white flex items-center justify-center rounded-full text-xl font-bold mb-4">
                        {user?.firstName?.[0].toUpperCase()}{user?.lastName?.[0]?.toUpperCase()}
                    </div>
                    <h3 className="text-xl font-medium text-gray-800">
                        {user?.firstName} {user?.lastName}
                    </h3>
                    <p className="text-gray-600 text-sm mb-4">{user?.emailId}</p>
                </div>
                <div className="mt-4">
                    <div className="flex justify-between items-center border-b py-2">
                        <span className="text-gray-600 font-medium">First Name</span>
                        <span className="text-gray-800">{user?.firstName}</span>
                    </div>
                    <div className="flex justify-between items-center border-b py-2">
                        <span className="text-gray-600 font-medium">Last Name</span>
                        <span className="text-gray-800">{user?.lastName}</span>
                    </div>
                    <div className="flex justify-between items-center border-b py-2">
                        <span className="text-gray-600 font-medium">Email</span>
                        <span className="text-gray-800">{user?.emailId}</span>
                    </div>

                    <div className="flex justify-between items-center py-2">
                        <span className="text-gray-600 font-medium">Account Created</span>
                        <span className="text-gray-800">
                            {new Date(user?.createdAt).toLocaleDateString()}
                        </span>
                    </div>
                    <div className='flex justify-end mt-10'>
                        <Link to={`/editprofile/${user?._id}`} state={user} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain rounded-xl'>
                            Edit
                        </Link>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Profile;
