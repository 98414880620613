import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'

const useGetBlogs = () => {
    const [blog, setBlog] = useState([])

    const fetchBlogs = async () => {
        try {
            const res = await fetch(BASE_URL + "allblogs")
            const json = await res.json()
            const data = json
            setBlog(data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchBlogs()
    }, [])

    return { blog }
}

export default useGetBlogs
