import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import axios from 'axios';
import { BASE_URL, IMAGE_BASE_URL } from '../utils/Helper';

const EditMainPortfolio = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location?.state;

    // Handle image change
    const handleImageChange = (e, index, setFieldValue) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setFieldValue(`images[${index}]`, { image: file, imageUrl }); // Update field value with image key
        }
    };

    const handleSubmit = async (values, { resetForm }) => {


        // Create FormData to submit
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('subTitle', values.subTitle);

        // Append images to formData
        values.images.forEach((image, index) => {
            if (image?.image) {
                formData.append(`image${index}`, image.image); // Send only the image file to the backend
            }
        });

        // Log FormData to check if it's being correctly formed
        console.log('FormData:', formData);

        try {
            const response = await axios.put(`${BASE_URL}updatemainportfolio/${data?._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });

            // console.log('Submit response:', response.data);

            resetForm();
            navigate('/portfolio');
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    return (
        <>
            <Formik
                initialValues={{
                    title: data?.title || '',
                    subTitle: data?.subTitle || '',
                    images: data?.image || [], // Assuming images are stored as an array
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, setFieldValue, resetForm }) => (
                    <Form encType="multipart/form-data">
                        <div className="flex justify-start px-4 sm:px-0">
                            <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                                <div className="pb-3">
                                    <p className="text-2xl font-semibold text-gray-700 mb-2">Edit MainPortfolio</p>
                                </div>

                                {/* Title Field */}
                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Title</p>
                                    <Field
                                        type="text"
                                        name="title"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage name="title" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* SubTitle Field */}
                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">SubTitle</p>
                                    <Field
                                        as="textarea"
                                        name="subTitle"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600 resize-none h-24"
                                    />
                                    <ErrorMessage name="subTitle" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* Images Section */}
                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Images</p>
                                    <FieldArray
                                        name="images"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.images.map((image, index) => (
                                                    <div key={index} className="flex items-center mb-4">
                                                        <div className="w-20 h-20 mr-4">
                                                            {image?.imageUrl ? (
                                                                <img
                                                                    src={image.imageUrl} // Display image preview
                                                                    alt={`Image ${index + 1}`}
                                                                    className="w-full h-full object-cover rounded"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`${IMAGE_BASE_URL}${image}`} // Display existing image if available
                                                                    alt={`Image ${index + 1}`}
                                                                    className="w-full h-full object-cover rounded"
                                                                />
                                                            )}
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name={`images[${index}].image`} // Set name as 'image' for each image input
                                                            onChange={(e) => handleImageChange(e, index, setFieldValue)}
                                                            className="file-input"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="flex py-3 gap-4">
                                    <Link to="/portfolio" className="btn text-white bg-red-500 px-4 py-2 rounded-lg hover:bg-red-800">
                                        Cancel
                                    </Link>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="btn text-white bg-blue-500 px-4 py-2 rounded-lg hover:bg-blue-600"
                                    >
                                        {isSubmitting ? 'Saving...' : 'Save Changes'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditMainPortfolio;
