import React from 'react'
import { useParams } from 'react-router-dom'
import usegetBlogById from '../customhooks/usegetBlogById'
import { IMAGE_BASE_URL } from '../utils/Helper'

const PostPage = () => {
    const { id } = useParams()
    const { data, loading } = usegetBlogById(id)
    console.log("data", data)

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-lg font-medium text-gray-500">Loading...</p>
            </div>
        )
    }

    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {data ? (
                <article className="space-y-6">
                    <header className="space-y-4">
                        <h1 className="text-3xl font-bold text-gray-800">{data?.serviceName}</h1>
                        <p className="text-xl text-gray-600">{data?.subtitle}</p>
                    </header>
                    {data?.images && data?.images.length > 0 && (
                        <div className="overflow-hidden rounded-lg shadow-lg">
                            <img
                                className="w-full object-cover"
                                src={data?.images[0]?.url || `${IMAGE_BASE_URL}/placeholder.jpg`}
                                alt={data?.serviceName}
                            />
                        </div>
                    )}

                    <section className="prose max-w-none prose-lg text-gray-700">
                        <h2 className="text-2xl font-semibold">{data?.title}</h2>
                        <p>{data?.content}</p>
                    </section>
                </article>
            ) : (
                <p className="text-lg font-medium text-gray-500">No data found for this blog post.</p>
            )}
        </div>
    )
}

export default PostPage
