import React from 'react'

const ChooseUs = () => {
    return (
        <div className='py-10'>
            <div className='mb-5'>
                <h1 className='text-xl text-textmain font-bold sm:text-2xl md:text-xl lg:text-2xl'>Why choose us!</h1>
            </div>
            <div className='flex justify-center '>
                <div className="carousel carousel-center bg- rounded-box  space-x-1 w-full ">

                    <div className="carousel-item bg-blueish w-96 rounded-xl">
                        <div className='text-white  p-3'>
                            <div className='text-center text-neutral bg-white inline-block p-3 rounded-full'>1</div>
                            <h1 className='text-center text-xl font-semibold 
                                py-3'>Extensive Venue Options.</h1>
                            <p className='mx-auto text-justify p-3'>
                                We offer a comprehensive range of venues to select a perfect place that meets your requirements. Our user-friendly platform makes it easy to choose a venue from the list, compare prices, and book your preferred venue in just a few clicks.
                            </p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className='text-white  p-3 bg-darkred w-96 rounded-xl'>
                            <div className='text-center text-neutral bg-white inline-block p-3 rounded-full'>2</div>
                            <h1 className='text-center text-xl font-semibold py-3'>Flexible Booking Options.</h1>
                            <p className='mx-auto text-justify p-3'>
                                We offer a comprehensive range of venues to select a perfect place that meets your requirements. Our user-friendly platform makes it easy to choose a venue from the list, compare prices, and book your preferred venue in just a few clicks.
                            </p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className='text-white  p-3 bg-darkr w-96 rounded-xl'>
                            <div className='text-center text-neutral bg-white inline-block p-3 rounded-full'>3</div>
                            <h1 className='text-center text-xl font-semibold py-3'>Professional wedding planners.</h1>
                            <p className='mx-auto text-justify p-3'>
                                We have hired certified professional wedding planners. Our Team has years of experience in event management & wedding planning to provide best on demand wedding services.  Our team is friendly and adaptive in nature to understand client requirements and desires for the wedding.
                            </p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className='text-white  p-3 bg-d w-96 rounded-xl'>
                            <div className='text-center text-neutral bg-white inline-block p-3 rounded-full'>4</div>
                            <h1 className='text-center text-xl font-semibold py-3'>All Services at one Platform</h1>
                            <p className='mx-auto text-justify p-3'>
                                We offer Best wedding services in Hubli, Dharwad, Harihar, Belgaum & all over karnataka.  Our broad selection of venues, knowledgeable staff, variety of vendors, prompt delivery, and dedication to our job allow us to maintain a solid position in this competitive wedding industry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ChooseUs
