import React from 'react';
import { Outlet } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoutes';
import Admin_Side_Nav_Bar from '../components/subcomponents/Admin_Side_Nav_Bar';

const AdminLayout = () => {
    return (
        <ProtectedRoute>
            <div className="flex min-h-screen">
                <Admin_Side_Nav_Bar />
                <div className="flex-grow p-4 ">
                    <Outlet />
                </div>
            </div>
        </ProtectedRoute>
    );
};

export default AdminLayout;
