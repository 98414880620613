import React from 'react';
import useGetCompantDetails from '../customhooks/useGetCompantDetails';
import { BASE_URL, IMAGE_BASE_URL } from '../utils/Helper';
import { Link, useNavigate } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaYoutube, FaFacebook } from "react-icons/fa";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { removeUser } from '../utils/redux/slices/useSlices';

const Footer = () => {
    const { data } = useGetCompantDetails();
    // console.log("data", data);

    const user = useSelector((store) => store.user.user)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleLogout = async () => {
        try {
            const res = await axios.post(BASE_URL + "logout", {}, { withCredentials: true })
            localStorage.removeItem("token")
            dispatch(removeUser())
            navigate("/")
            return toast.success("Logged Out!")
        } catch (error) {
            console.error(error)
            return toast.error(error)
        }
    }




    return (
        <div>
            {data && data.length > 0 && (
                <footer className="footer bg-base-200 text-base-content p-6 md:p-10 z-10 flex flex-col md:flex-row md:justify-between gap-6">
                    <aside className="flex flex-col items-center md:items-start space-y- text-center md:text-left">
                        <img
                            className="w-24 md:w-32 rounded-full"
                            src={IMAGE_BASE_URL + data[0]?.image}
                            alt="WC logo"
                        />
                        <p className="text-lg md:text-2xl font-bold m-0">
                            {data[0]?.name}
                        </p>
                        <p className="text-sm m-0">
                            {data[0]?.address}
                        </p>
                        <p className="text-sm m-0">
                            {data[0]?.phone}
                        </p>
                        <p className="text-sm m-0">
                            {data[0]?.emailId}
                        </p>
                        <div className="flex justify-center md:justify-start space-x-4 mt-2">
                            <Link to="https://www.instagram.com/weddingcluster_official/" target="_blank">
                                <FaInstagram className="text-pink-600 hover:text-pink-700 text-xl md:text-2xl" />
                            </Link>
                            <Link to="https://www.facebook.com/weddingcluster/" target="_blank">
                                <FaFacebook className="text-blue-800 hover:text-blue-900 text-xl md:text-2xl" />
                            </Link>
                            <Link to="https://www.linkedin.com/in/wedding-cluster-a9673a2b9/" target="_blank">
                                <FaLinkedin className="text-blue-600 hover:text-blue-700 text-xl md:text-2xl" />
                            </Link>
                            <Link to="https://www.youtube.com/@weddingcluster1" target="_blank">
                                <FaYoutube className="text-red-600 hover:text-red-700 text-xl md:text-2xl" />
                            </Link>
                        </div>
                    </aside>

                    <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-10 text-center md:text-left">
                        <nav>
                            <h6 className="footer-title text-lg font-semibold">Services</h6>
                            <p className="link link-hover">Branding</p>
                            <p className="link link-hover">Design</p>
                            <p className="link link-hover">Marketing</p>
                            <p className="link link-hover">Advertisement</p>
                            {user && user ? (
                                <Link onClick={handleLogout} className="link link-hover">Logout</Link>

                            ) : (
                                <>
                                    <Link to={`/register`} className="link link-hover"> Register| </Link>
                                    <Link to={`/login`} className="link link-hover">Login</Link>
                                </>
                            )}

                        </nav>
                        <nav>
                            <h6 className="footer-title text-lg font-semibold">Company</h6>
                            <Link to={`/blog`} className="link link-hover">Blog</Link>
                            <p className="link link-hover"></p>
                            <Link to={`/about-us`} className="link link-hover">About us</Link>
                            <br />
                            <Link to={`/contact-us`} className="link link-hover">Contact</Link>

                        </nav>
                        <nav>
                            <h6 className="footer-title text-lg font-semibold">Legal</h6>
                            <Link to={`/terms&condition`} className="link link-hover">Terms of use</Link>
                            <br />
                            <Link to={`/privacy&policy`} className="link link-hover">Privacy policy</Link>
                            <p className="link link-hover">Cookie policy</p>
                        </nav>
                    </div>

                    <div className="flex justify-center md:justify-end w-full md:w-auto mt-4 md:mt-0">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3847.1779855765935!2d75.11881277478287!3d15.366844085215424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d7376cee471f%3A0xd1628aae8d84451a!2sWedding%20Cluster!5e0!3m2!1sen!2sin!4v1697187521924!5m2!1sen!2sin"
                            className="h-40 w-full md:w-80 border-0"
                            style={{ filter: "opacity(0.8)" }}
                            allowFullScreen
                            loading="lazy"
                        ></iframe>
                    </div>
                </footer>
            )}
        </div>
    );
};

export default Footer;
