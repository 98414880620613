import React, { useState } from 'react';

function ContactUsModal({ isModalOpen, setIsModalOpen }) {
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        let newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.phone) newErrors.phone = 'Phone is required';
        if (!formData.message) newErrors.message = 'Message is required';
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await fetch('http://localhost:3511/api/contactus/create-contact', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    // Reset form and close modal on success
                    setFormData({ name: '', email: '', phone: '', message: '' });
                    setIsModalOpen(false);
                } else {
                    console.log('Error submitting form');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    return (
        <div>
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-full max-w-3xl mx-auto md:flex rounded-lg shadow-lg overflow-hidden">
                        <div className="hidden md:block w-1/2 bg-cover" style={{ backgroundImage: 'url(https://via.placeholder.com/300)' }}></div>
                        <div className="w-full md:w-1/2 p-8 relative">
                            <button
                                className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                                onClick={() => setIsModalOpen(false)}
                            >
                                &times;
                            </button>

                            <h2 className="text-xl md:text-2xl lg:text-3xl font-bold py-3 text-textmain">
                                ENQUIRIES, QUESTIONS AND APPOINTMENTS
                            </h2>
                            <p className="text-gray-600 mb-4">
                                Fill in the form below and we will get back to you as soon as we can:
                            </p>

                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div className="grid grid-cols-1 gap-4">
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-2 border border-blue-950 rounded"
                                        placeholder="Your Name"
                                        required
                                    />
                                    {errors.name && <p className="text-red-500">{errors.name}</p>}
                                </div>

                                <div className="grid grid-cols-1 gap-4">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full p-2 border border-blue-950 rounded"
                                        placeholder="Your Email"
                                        required
                                    />
                                    {errors.email && <p className="text-red-500">{errors.email}</p>}
                                </div>

                                <div className="grid grid-cols-1 gap-4">
                                    <input
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className="w-full p-2 border border-blue-950 rounded"
                                        placeholder="Your Phone Number"
                                        required
                                    />
                                    {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                                </div>

                                <div>
                                    <label className="block text-gray-700">Message</label>
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        rows="4"
                                        className="w-full p-2 border border-blue-950 rounded"
                                        placeholder="Your Message"
                                        required
                                    ></textarea>
                                    {errors.message && <p className="text-red-500">{errors.message}</p>}
                                </div>

                                <button type="submit" className="bg-blue-950 text-white p-2 rounded w-full">
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContactUsModal;
