import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const UpdateService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`http://localhost:3511/api/services/getservice-byId/${id}`);
        setServiceDetails(response.data.data);
      } catch (err) {
        setErrorMessage('Error fetching service details');
      }
    };

    fetchServices();
  }, [id]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Service name must be at least 3 characters long.')
      .max(50, 'Service name must be no longer than 50 characters.')
      .required('Service name is required.'),
    description1: Yup.string().required('Description 1 is required.'),
    description2: Yup.string(),
    photos: Yup.mixed()
      .notRequired()
      .test('fileSize', 'Each file must be less than 5MB.', (value) => {
        return !value || Array.from(value).every(file => file.size <= 5 * 1024 * 1024);
      })
      .test('fileType', 'Only JPEG, JPG, and PNG files are allowed.', (value) =>
        !value || Array.from(value).every(file => ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type))
      ),
  });

  const handleSubmit = async (values) => {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('description1', values.description1);
    formData.append('description2', values.description2);

    if (values.photos && values.photos.length > 0) {
      Array.from(values.photos).forEach((file) => {
        formData.append('photos', file);
      });
    }

    try {
      const response = await fetch(`http://localhost:3511/api/services/updateservice-byId/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
        body: formData,
        credentials: 'include',
      });

      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Service updated successfully!');
        setErrorMessage('');
      } else {
        setErrorMessage(data.message || 'Failed to update service.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error updating service:', error);
      setErrorMessage('Error updating the service.');
      setSuccessMessage('');
    }
  };

  if (!serviceDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex justify-center mt-10">
      <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
        <button onClick={() => navigate(-1)} className="p-2 m-2 w-20 text-white rounded-lg bg-blue-950">Back</button>

        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Update Service</h2>

        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
        {successMessage && <p className="text-green-600 text-center">{successMessage}</p>}

        <Formik
          enableReinitialize
          initialValues={{
            name: serviceDetails?.name || '',
            description1: serviceDetails?.description1 || '',
            description2: serviceDetails?.description2 || '',
            photos: [],  // Initialize as an empty array, handle file uploads separately
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Service Name</label>
                <Field
                  id="name"
                  name="name"
                  placeholder="Enter service name"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="name" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description1" className="block text-sm font-medium text-gray-700">Description 1</label>
                <Field
                  as="textarea"
                  id="description1"
                  name="description1"
                  placeholder="Enter description 1"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="description1" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description2" className="block text-sm font-medium text-gray-700">Description 2</label>
                <Field
                  as="textarea"
                  id="description2"
                  name="description2"
                  placeholder="Enter description 2"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="description2" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="photos" className="block text-sm font-medium text-gray-700">Upload Photos</label>
                <input
                  id="photos"
                  name="photos"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => setFieldValue('photos', e.currentTarget.files)}
                  className="mt-2 p-2 border w-full rounded-md"
                />
                {values.photos && values.photos.length > 0 && (
                  <div className="mt-2">
                    <h4 className="text-sm font-medium">Selected files:</h4>
                    <ul className="text-sm text-gray-600">
                      {Array.from(values.photos).map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <ErrorMessage name="photos" component="div" className="text-red-600 text-sm" />
              </div>

              <button
                type="submit"
                className="w-full mt-4 p-2 bg-blue-600 text-white font-semibold rounded-md"
              >
                Update Service
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateService;
