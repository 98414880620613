import React from 'react'

const VideoCard = () => {
    return (
        <>
            <div className='py-10'>
                <div className='py-7'>
                    <h2
                        className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] inline-block"
                        aria-label="Portfolio Section"
                    >
                        Videos
                    </h2>
                </div>

                <div className="flex flex-col md:flex-row justify-between gap-6">
                    <div className="w-full md:w-[600px] h-[340px]">

                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/1pT62KIp9ps"
                            title="Best Wedding Planners in Karnataka"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                        ></iframe>
                    </div>
                    <div className="w-full md:w-[600px] h-[340px]">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/NmF9E_3KzUg" title="Best wedding services in India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoCard
