import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReduces from "./slices/useSlices"
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
    key: "WC-Website",
    version: 1,
    storage,
};


const rootReducer = combineReducers({
    user: userReduces,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        }),
    devTools: false,
});



const persistor = persistStore(store);


export { store, persistor };
