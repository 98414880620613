import { useState, useEffect } from 'react';
import axios from "axios";
import { BASE_URL } from '../utils/Helper';

const useGetBlogById = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getBlogById = async () => {
        try {
            setLoading(true); // Set loading to true before making the request
            const res = await axios.get(`${BASE_URL}/get-blog-by-id/${id}`);
            setData(res.data?.data);
            setError(null);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getBlogById();
        }
    }, [id]);

    return { data, loading, error };
};

export default useGetBlogById;
