import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import useFetchMainPortfolio from '../utils/customHooks/useFetchMainPortfolio';
import { IMAGE_BASE_URL } from '../utils/Helper';
import SubPortFolio from './subcomponents/SubPortFolio';
import useFetchSubPortfolio from '../customhooks/useFetchSubPortfolio';

const Portfolio = () => {
  const { portFoliImages, loading, error } = useFetchMainPortfolio();
  // console.log('portfolio images', portFoliImages)

  const { subPortFolio } = useFetchSubPortfolio()
  console.log("subPortFolio", subPortFolio)

  const mainPhotos = portFoliImages?.data[0]?.image
  // console.log("mainPhotos", mainPhotos)

  if (loading) {
    return <h1 className="text-center text-lg sm:text-xl">Loading...</h1>;
  }

  if (!subPortFolio) return null;

  return (
    <>
      <div className="px-4 sm:px-8 md:px-16 lg:px-24">
        <div className=" gap-4">
          {portFoliImages && portFoliImages.data[0] && (
            <section className="p-4">
              <h1
                className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] inline-block"
                aria-label="Portfolio Section"
              >
                {portFoliImages.data[0]?.title}
              </h1>
              <p className="py-5 text-textmain text-base sm:text-lg md:text-lg">
                {portFoliImages.data[0]?.subTitle}
              </p>
            </section>
          )}
        </div>
      </div>

      <div className=" w-full mx-2">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ type: "spring", stiffness: 100, damping: 30, duration: 1 }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-1">
            {mainPhotos?.map((src, index) => (
              <div key={index} className="relative group overflow-hidden cursor-pointer mb-0">
                <img
                  src={IMAGE_BASE_URL + src}
                  alt="Venue"
                  className="w-full h-60 transition-transform duration-300 transform group-hover:scale-105"
                />
              </div>
            ))}
          </div>
        </motion.div>

      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 mt-20">
        <SubPortFolio subPortFolio={subPortFolio} />
      </div>
    </>
  );
};

export default Portfolio;
