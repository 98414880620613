import React from 'react'
import { MdOutlineArrowOutward } from 'react-icons/md'
import { Link } from 'react-router-dom'
import ChooseUs from './subcomponents/ChooseUs'
import Counts from './subcomponents/Counts'
import Seen from './subcomponents/Seen'
import ClientComment from './subcomponents/ClientComment'
import Footer from './Footer'

const About = () => {
    return (
        <>
            <div className='px-4 sm:px-8 md:px-16 lg:px-24'>
                <div className='py-4'>
                    <h1 className='text-xl md:text-2xl lg:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] inline-block'>
                        About Wedding Cluster.
                    </h1>
                    <p className='py-5 text-textmain text-base sm:text-lg md:text-lg'>
                        Wedding Cluster is a platform that offers people to find & book the best wedding venue anywhere in India at affordable cost. Wedding Cluster has years of experience in the wedding industry and has successfully completed 200+ weddings. Within a short span the wedding cluster became a trusted brand for best wedding services. We offer a complete spectrum of wedding services that includes venue booking, design & decor, catering, music, mehndi & makeup etc..
                    </p>
                </div>
                <div className='w-full'>
                    <img className='w-full h-96' src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8QDw0NDQ0NDQ0NDQ0NDQ0NDQ8NDQ0NFREWFhURFRMYHSggGBolGxMVITEhJSk3LjouFx8/ODMtNyg5LjcBCgoKDQ0NFQ8PFSsZFRkrKystKysrLTcrKysrNzc3LTctLSsrLS03Nys3LTctLS0rLS03LS0rLTctLS0rKy0tK//AABEIAMkA+wMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIH/8QAFxABAQEBAAAAAAAAAAAAAAAAAAERAv/EABcBAQEBAQAAAAAAAAAAAAAAAAABBgT/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/AOTxcIONoxKVbAIqSGimmlTBBWVADF0CKkUVCQigkpTQQIABKqSCqmqyC4EQQi1ADF0TAKjUgBTSAoSiCLDBdFEqUwQiww0CxKtpIBCqCojWs0QqooJBVBIrOtCpUVBFhaiwEqyBKKqUAIuMrohpVQUlKkXBChQEpFADCAFIgC6Jq0CJQgCyBKBoUgJilQFQXQNNNAQiwgAAINJgCstQVCUOhCiSqARJWgBDRSpa1YgiLiVaCCxAUTFgCYqUFQwgKQ1KC4EADUAWJWpEwCVNVAXFSUFEF0RI0hAgqAq4zGqgLEAA0hgiEUAVNBUU0EEqgIaYYBhq6aBEwlW0FiSkqQF1Uw0VItSVbREIsTQVUBQRYIRUoKKlAIqKCCLREpKaAokaFQhSABhACFoIqCALEKC1NVMAixKsBKolAi6iwDUqpQGmVCC4hRTQAIYRQZwXUENWVFwBUAA0AommAUVAMUICC6aCVYIC1FQFKAqKAiSNIUUIJBFoVNFWKgCFi4QRIspQBUKKIpBEKsQDFlCgaqSFFSguCJq1lqAhGjBcSouAGhaCAUgpDBKIukRYAYaAqEWwVAQQWRFgINYyACgAAamiwDEXQVFiKIhqoBotjINAAU00oGmAAlWAECqKkWpaACQENJSgNWoUgEEWClRbEEFRQSrhihGWmasAKkKCiRYBS0IBKaGCqiLBChU0FNVnADQBSCSgAuAQSLQBMUCiEAxVZwFCsgoYAQMUEilgAJQFAgpCgIaUBUUUGRdMEEqpQUlRrQQqoKhVJBEjSQFAQQMACmLVBIaVAXUooIRYlA00QFiotBUqQoC6hyCyHUVKCIpARSkBaaUoBEUEi4KKgKCVMKgj/9k=" alt="" />
                </div>
                <ChooseUs />
                <Counts />
            </div>
            <div>
                <Seen />
            </div>
            <div className='px-4 sm:px-8 md:px-16 lg:px-24'>
                <ClientComment />
            </div>
            <Footer />
        </>
    )
}

export default About