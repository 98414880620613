import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../utils/Helper';
import axios from 'axios';

const EditBlog = () => {
    const location = useLocation();
    const data = location?.state;
    const initialData = data;

    const navigate = useNavigate()

    const validationSchema = Yup.object({
        serviceName: Yup.string().required('Service name is required'),
        title: Yup.string().required('Title is required'),
        subtitle: Yup.string().required('Subtitle is required'),
        content: Yup.string().required('Content is required'),
        images: Yup.array().of(
            Yup.object().shape({
                url: Yup.string().url('Invalid URL format').required('Image URL is required')
            })
        ),
    });

    const handleonSubmit = async (values) => {
        console.log("Form submitted with values:", values); // Add this line
        try {
            const res = await axios.put(`${BASE_URL}editblog/${initialData._id}`, values, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });
            console.log(res);

            navigate('/blogpage');
        } catch (error) {
            console.error("Error updating blog post:", error);
        }
    };

    return (
        <Formik
            initialValues={{
                serviceName: initialData.serviceName || '',
                title: initialData.title || '',
                subtitle: initialData.subtitle || '',
                content: initialData.content || '',
                images: initialData.images || [],
            }}
            validationSchema={validationSchema}
            onSubmit={handleonSubmit}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <div className="flex justify-start px-4 sm:px-0">
                        <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                            <div className="pb-3">
                                <p className="text-2xl font-semibold text-gray-700 mb-2">Update Blog</p>
                            </div>

                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Service Name</p>
                                <Field
                                    type="text"
                                    name="serviceName"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                />
                                <ErrorMessage name="serviceName" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Title</p>
                                <Field
                                    type="text"
                                    name="title"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                />
                                <ErrorMessage name="title" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Subtitle</p>
                                <Field
                                    type="text"
                                    name="subtitle"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                />
                                <ErrorMessage name="subtitle" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Content</p>
                                <Field
                                    as="textarea"
                                    name="content"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                />
                                <ErrorMessage name="content" component="div" className="text-red-500 text-sm mt-1" />
                            </div>
                            <div className="mb-4">
                                <p className="text-lg text-gray-700 mb-2">Images</p>
                                {values.images.map((image, index) => (
                                    <div key={index} className="flex items-center mb-2">
                                        <Field
                                            type="text"
                                            name={`images[${index}].url`}
                                            placeholder="Image URL"
                                            className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                        />
                                        <ErrorMessage name={`images[${index}].url`} component="div" className="text-red-500 text-sm mt-1" />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                const images = [...values.images];
                                                images.splice(index, 1);
                                                setFieldValue("images", images);
                                            }}
                                            className="text-red-500 ml-2"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => setFieldValue("images", [...values.images, { url: '' }])}
                                    className="text-blue-500 mt-2"
                                >
                                    Add Image
                                </button>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-textmain text-white rounded-lg px-3 py-2 font-semibold hover:bg-blue-950 transition duration-200"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EditBlog;
