import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../utils/Helper';

const useFeedApi = () => {
    const [feed, setFeed] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleFeedApi = async () => {
        try {
            const res = await axios.get(`${BASE_URL}feed`);
            const data = res?.data;
            setFeed(data);
        } catch (error) {
            console.error(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleFeedApi();
    }, []);

    return { feed, loading, error };
};

export default useFeedApi;
