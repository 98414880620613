import React from 'react'
import { IMAGE_BASE_URL } from '../../utils/Helper'
import VideoCard from './VideoCard'

const SubPortFolio = ({ subPortFolio }) => {
    return (
        <>
            {subPortFolio && subPortFolio.slice(0, 2).map((data, index) => (
                <div key={index} className="mt-5">
                    <div>
                        <h2
                            className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] inline-block"
                            aria-label="Portfolio Section"
                        >
                            {data?.title}
                        </h2>
                        <p className="text-md text-gray-600 mt-1">{data?.subtitle}</p>
                    </div>
                    <div className="flex overflow-x-scroll gap-4 sm:gap-6 py-3">
                        {data?.images?.map((item, imgIndex) => (
                            <img
                                key={imgIndex}
                                className="w-48 h-32 sm:w-60 sm:h-40 md:w-72 md:h-48 object-cover rounded-lg shadow-lg"
                                src={`${IMAGE_BASE_URL}${item}`}
                                alt={data?.title || "Portfolio Image"}
                            />
                        ))}
                    </div>
                </div>
            ))}

            <VideoCard />

            {subPortFolio && subPortFolio.slice(2).map((data, index) => (
                <div key={index} className="mt-5">
                    <div>
                        <h2
                            className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] inline-block"
                            aria-label="Portfolio Section"
                        >
                            {data?.title}
                        </h2>
                        <p className="text-md text-gray-600 mt-1">{data?.subtitle}</p>
                    </div>
                    <div className="flex overflow-x-scroll gap-4 sm:gap-6 py-3">
                        {data?.images?.map((item, imgIndex) => (
                            <img
                                key={imgIndex}
                                className="w-48 h-32 sm:w-60 sm:h-40 md:w-72 md:h-48 object-cover rounded-lg shadow-lg"
                                src={`${IMAGE_BASE_URL}${item}`}
                                alt={data?.title || "Portfolio Image"}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </>
    )
}

export default SubPortFolio
