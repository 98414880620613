import React from 'react'
import { IMAGE_BASE_URL } from '../../utils/Helper'

const AllServiceCarosul = ({ service }) => {
    console.log("service", service)

    if (!service) return <h1 className="text-center text-lg sm:text-xl">Loading...</h1>;
    return (
        <div className="space-y-10 py-12">
            {service && service?.map((data, index) => (
                <div key={data?._id} className="flex flex-col md:flex-row items-center">
                    {index % 2 === 0 ? (
                        <>
                            <img
                                className="w-full md:w-1/2 object-cover rounded-md"
                                src={IMAGE_BASE_URL + data?.photoUrl}
                                alt={data?.name}
                            />
                            <div className="md:w-1/2 p-4">

                                <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] font-semibold text-xl md:text-2xl lg:text-3xl inline-block mb-2">
                                    {data?.name}
                                </p>

                                <p className="text-base text-gray-800 text-justify mb-2 p-2">
                                    {data?.description1}
                                </p>
                                <p className="text-base text-gray-800 text-justify mb-4 p-2">
                                    {data?.description2}
                                </p>

                                <div className="mt-6">
                                    <button className="bg-[#EBB882] text-black font-bold py-2 px-4 rounded hover:bg-[#1D2842] hover:text-white transition duration-200 mt-4">
                                        Contact Us
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="md:w-1/2 p-4">
                                <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] font-semibold text-xl md:text-2xl lg:text-3xl inline-block mb-2">
                                    {data?.name}
                                </p>

                                <p className="text-base text-gray-800 text-justify mb-2 p-2">
                                    {data?.description1}
                                </p>
                                <p className="text-base text-gray-800 text-justify mb-4 p-2">
                                    {data?.description2}
                                </p>

                                <div className="mt-6">
                                    <button className="bg-[#EBB882] text-black font-bold py-2 px-4 rounded hover:bg-[#1D2842] hover:text-white transition duration-200 mt-4">
                                        Contact Us
                                    </button>
                                </div>
                            </div>
                            <img
                                className="w-full md:w-1/2 object-cover rounded-md"
                                src={IMAGE_BASE_URL + data?.photoUrl}
                                alt={data?.name}
                            />
                        </>
                    )}
                </div>
            ))}
        </div>




    )
}

export default AllServiceCarosul
