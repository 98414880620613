import React from 'react'


const ClientSay = ({ feedBack }) => {
    console.log("feedBack", feedBack)
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }
    return (
        <>
            <div className='px-4 sm:px-8 md:px-16 mt-20'>
                <h1 className='text-xl md:text-2xl lg:text-3xl font-bold text-textmain'>Our Client Say's</h1>
            </div>

            <div className='px-4 sm:px-8 md:px-16 mt-6 bg-textmain mb-12'>

                <div className='flex flex-col sm:flex-row sm:flex-wrap sm:justify-between gap-6 '>
                    {feedBack && feedBack?.slice(0, 4).map((data) => (
                        <div className='w-full sm:w-72 mt-6'>
                            <div className="rounded-md hover:-translate-y-3 duration-200 bg-base-100 border border-gray-200 hover:shadow-xl p-5 ">
                                <h2 className="text-sm font-semibold">{data?.description
                                }</h2>
                            </div>
                            <div className='mt-3 flex gap-4 items-center mb-12'>
                                <div className="avatar">
                                    <div className="w-16 rounded-full">
                                        <img src="https://github.com/Guheshp/microservices/blob/main/weddingimage.jpg?raw=true" alt="Jassamine" />
                                    </div>
                                </div>
                                <div>


                                    <p className='text-white'>
                                        {capitalizeFirstLetter(data?.groomName)} & {capitalizeFirstLetter(data?.brideName)}
                                    </p>

                                    <p className='text-sm text-gray-400'>
                                        {`${new Date(data?.date).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })} at ${new Date(data?.date).toLocaleTimeString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        })}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}


                </div>

            </div >
        </>
    )
}

export default ClientSay
