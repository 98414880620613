import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BASE_URL, IMAGE_BASE_URL } from "../utils/Helper";
import axios from "axios";

const EditCompanyProfile = () => {
    const location = useLocation();
    const companyData = location?.state;

    const [previewImage, setPreviewImage] = useState(
        companyData?.image ? `${IMAGE_BASE_URL}${companyData.image}` : ""
    );

    // Validation Schema using Yup
    const validationSchema = Yup.object({
        name: Yup.string().required("Company Name is required"),
        emailId: Yup.string().email("Invalid email").required("Email is required"),
        phone: Yup.string()
            .matches(/^\d{10}$/, "Phone number must be 10 digits")
            .required("Phone number is required"),
        address: Yup.string().required("Address is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        pincode: Yup.string()
            .matches(/^\d{6}$/, "Pincode must be 6 digits")
            .required("Pincode is required"),
        image: Yup.mixed().nullable(),
    });

    const navigate = useNavigate()

    const handleOnSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("emailId", values.emailId);
            formData.append("phone", values.phone);
            formData.append("address", values.address);
            formData.append("city", values.city);
            formData.append("state", values.state);
            formData.append("pincode", values.pincode);

            if (values.image) {
                formData.append("image", values.image);
            }

            const response = await axios.put(
                `${BASE_URL}editcompany/${companyData._id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    withCredentials: true,
                }
            );

            console.log("Response:", response.data);
            setSubmitting(false);

            navigate("/companyprofile");
        } catch (error) {
            console.error("Error updating company profile:", error);
            setSubmitting(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                <Formik
                    initialValues={{
                        name: companyData?.name || "",
                        emailId: companyData?.emailId || "",
                        phone: companyData?.phone || "",
                        address: companyData?.address || "",
                        city: companyData?.city || "",
                        state: companyData?.state || "",
                        pincode: companyData?.pincode || "",
                        image: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleOnSubmit}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <div>
                                <div className="pb-3">
                                    <p className="text-2xl font-semibold text-gray-700 mb-2">
                                        Edit Company Profile
                                    </p>
                                </div>

                                {/* Company Name */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">Company Name</label>
                                    <Field
                                        type="text"
                                        name="name"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="text-red-500 text-sm mt-1"
                                    />
                                </div>

                                {/* Email */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">Email</label>
                                    <Field
                                        type="email"
                                        name="emailId"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage
                                        name="emailId"
                                        component="div"
                                        className="text-red-500 text-sm mt-1"
                                    />
                                </div>

                                {/* Phone */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">Phone Number</label>
                                    <Field
                                        type="text"
                                        name="phone"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage
                                        name="phone"
                                        component="div"
                                        className="text-red-500 text-sm mt-1"
                                    />
                                </div>

                                {/* Address */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">Address</label>
                                    <Field
                                        type="text"
                                        name="address"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage
                                        name="address"
                                        component="div"
                                        className="text-red-500 text-sm mt-1"
                                    />
                                </div>

                                {/* City */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">City</label>
                                    <Field
                                        type="text"
                                        name="city"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage
                                        name="city"
                                        component="div"
                                        className="text-red-500 text-sm mt-1"
                                    />
                                </div>

                                {/* State */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">State</label>
                                    <Field
                                        type="text"
                                        name="state"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage
                                        name="state"
                                        component="div"
                                        className="text-red-500 text-sm mt-1"
                                    />
                                </div>

                                {/* Pincode */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">Pincode</label>
                                    <Field
                                        type="text"
                                        name="pincode"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage
                                        name="pincode"
                                        component="div"
                                        className="text-red-500 text-sm mt-1"
                                    />
                                </div>

                                {/* Image Upload */}
                                <div className="mb-4">
                                    <label className="block text-lg text-gray-700 mb-2">Company Image</label>
                                    {previewImage && (
                                        <img
                                            src={previewImage}
                                            alt="Preview"
                                            className="w-32 h-32 object-cover rounded-md mb-2"
                                        />
                                    )}
                                    <input
                                        type="file"
                                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                        onChange={(event) => {
                                            const file = event.currentTarget.files[0];
                                            setFieldValue("image", file);
                                            setPreviewImage(URL.createObjectURL(file));
                                        }}
                                    />
                                </div>

                                {/* Submit Button */}
                                <button
                                    type="submit"
                                    className="w-full bg-blue-600 text-white rounded-lg px-3 py-2 font-semibold hover:bg-blue-700 transition duration-200"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Updating..." : "Update"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default EditCompanyProfile;
