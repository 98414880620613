import React from 'react';
import { Link } from 'react-router-dom';

function Admin_Side_Nav_Bar() {
    return (
        <div className="w-56 border-r bg-base-200 min-h-screen">
            <div className="">
                <ul className="menu text-base-content p-4 w-60">
                    <li>
                        <Link to={`/dashbord`} className="hover:bg-base-300 rounded">Dashbord</Link>
                    </li>
                    <li>
                        <Link to={`/slider`} className="hover:bg-base-300 rounded">Slider</Link>
                    </li>
                    <li>
                        <Link to={`/mainportfolio`} className="hover:bg-base-300 rounded">MainPortfolio</Link>
                    </li>
                    <li><Link to={`/blogpage`} className="hover:bg-base-300 rounded">Blogs</Link></li>
                    <li><Link to={`/companyprofile`} className="hover:bg-base-300 rounded">Company Profile</Link></li>
                    <li><Link to='/adminpage/create-service'>Service</Link></li>
                    <li><Link to='/adminpage/subportfolio'>Subportfoli</Link></li>
                    <li><Link to='/adminpage/feedbacks'>Feedback</Link></li>

                </ul>
            </div>
        </div>
    );
}

export default Admin_Side_Nav_Bar;
