import React from 'react'
import useGetCompanyProfile from '../customhooks/useGetCompanyProfile'
import { IMAGE_BASE_URL } from '../utils/Helper'
import { Link } from "react-router-dom"

const CompanyProfile = () => {
    const { data, loading } = useGetCompanyProfile()
    console.log("data", data)
    const companydata = data && data[0]
    return (
        <div class="p-6rounded-lg ">
            <div className='flex justify-end'>
                <Link to={`/editcompany/${companydata?._id}`} state={companydata} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain rounded-xl'>
                    Edit
                </Link>
            </div>
            {companydata && (
                <div class="flex flex-col items-center text-center">
                    <img
                        src={IMAGE_BASE_URL + companydata?.image}
                        alt="company image"
                        class="w-32 h-32 rounded-full object-cover mb-4"
                    />
                    <p class="text-xl font-semibold text-gray-800">{companydata?.name}</p>
                    <p class="text-gray-600">{companydata?.emailId}</p>
                    <p class="text-gray-600">{companydata?.phone}</p>
                    <p class="text-gray-600">
                        {companydata?.address}
                        <span>, {companydata?.city}</span>
                        <span>, {companydata?.state}</span>
                    </p>
                </div>
            )}
        </div>

    )
}

export default CompanyProfile
