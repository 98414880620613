import React from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { BASE_URL } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';

const NewBlog = () => {

    const navigate = useNavigate()

    const validationSchema = Yup.object({
        serviceName: Yup.string().required('Service name is required'),
        // title: Yup.string().required('Title is required'),
        subtitle: Yup.string().required('Subtitle is required'),
        content: Yup.string().required('Content is required'),
        images: Yup.array().of(
            Yup.object().shape({
                url: Yup.string().url('Invalid URL format').required('Image URL is required')
            })
        ),
    });


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        console.log(values);


        try {
            const res = await axios.post(`${BASE_URL}newblog`, values, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });
            console.log(res);

            navigate('/blogpage');
        } catch (error) {
            console.error(error)
        }



        setSubmitting(false);
        resetForm();
    };
    return (
        <>
            <Formik
                initialValues={{
                    serviceName: '',
                    title: '',
                    subtitle: '',
                    content: '',
                    images: [],
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, setFieldValue, resetForm }) => (
                    <Form encType="multipart/form-data">
                        <div className="flex justify-start px-4 sm:px-0">
                            <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                                <div className="pb-3">
                                    <p className="text-2xl font-semibold text-gray-700 mb-2">New Blog</p>
                                </div>
                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Title</p>
                                    <Field
                                        type="text"
                                        name="title"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage name="title" component="div" className="text-red-500 text-sm mt-1" />
                                </div>


                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Service Name</p>
                                    <Field
                                        type="text"
                                        name="serviceName"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage name="serviceName" component="div" className="text-red-500 text-sm mt-1" />
                                </div>


                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Subtitle</p>
                                    <Field
                                        type="text"
                                        name="subtitle"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage name="subtitle" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Content</p>
                                    <Field
                                        as="textarea"
                                        name="content"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage name="content" component="div" className="text-red-500 text-sm mt-1" />
                                </div>
                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Images</p>
                                    {values.images.map((image, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            {/* File Input for Image Upload */}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.onload = () => {
                                                            const images = [...values.images];
                                                            images[index].url = reader.result; // Use base64 or handle file upload logic here
                                                            setFieldValue("images", images);
                                                        };
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                                className="border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600 mr-2"
                                            />

                                            {/* Field for URL Input */}
                                            <Field
                                                type="text"
                                                name={`images[${index}].url`}
                                                placeholder="Image URL"
                                                className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                                value={values.images[index].url} // Keep URL field synchronized
                                                onChange={(e) => {
                                                    const images = [...values.images];
                                                    images[index].url = e.target.value;
                                                    setFieldValue("images", images);
                                                }}
                                            />
                                            <ErrorMessage name={`images[${index}].url`} component="div" className="text-red-500 text-sm mt-1" />

                                            {/* Remove Button */}
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const images = [...values.images];
                                                    images.splice(index, 1);
                                                    setFieldValue("images", images);
                                                }}
                                                className="text-red-500 ml-2"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    ))}

                                    {/* Add Image Button */}
                                    <button
                                        type="button"
                                        onClick={() => setFieldValue("images", [...values.images, { url: '' }])}
                                        className="text-blue-500 mt-2"
                                    >
                                        Add Image
                                    </button>
                                </div>


                                <div className="pt-4">
                                    <button
                                        type="submit"
                                        className="w-full bg-blue-500 text-white rounded-lg px-3 py-2 font-semibold hover:bg-blue-600 transition duration-200"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default NewBlog
