import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from '../../../utils/modals/ConfirmDeleteModal';

const Feedback = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [feedbackIdToDelete, setFeedbackIdToDelete] = useState(null);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const response = await axios.get('http://localhost:3511/api/feedback/getall-feedback');
                setFeedbacks(response.data.feedbacks);
            } catch (err) {
                setError('Error fetching feedbacks');
            }
        };

        fetchFeedbacks();
    }, []);

    const handleDelete = async () => {
        try {
            await axios.delete(`http://localhost:3511/api/feedback/delete-feedback-byID/${feedbackIdToDelete}`);
            setFeedbacks(feedbacks.filter(feedback => feedback._id !== feedbackIdToDelete));
            setIsModalOpen(false);
        } catch (err) {
            console.error('Error deleting feedback:', err);
        }
    };

    const openModal = (id) => {
        setFeedbackIdToDelete(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setFeedbackIdToDelete(null);
    };

    return (
        <div className="mt-5">
            <div className="mb-4 flex justify-end">
                <Link to={'/adminpage/add-feedback'} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain p-1 rounded-xl '>
                    + Add Feedback
                </Link>
            </div>
            {error && <p>{error}</p>}

            <div className="flex flex-col flex-wrap">
                {feedbacks.map((feedback) => (
                    <div key={feedback._id} className="border p-4 rounded-lg shadow-lg mb-6">
                        <h3 className="text-xl font-semibold text-center">{feedback.brideName}</h3>
                        <h4 className="text-lg text-center">{feedback.groomName}</h4>
                        <p className="text-lg text-center">{feedback.description || 'No Comment'}</p>

                        <div className="mt-2">
                            <strong>Location:</strong> {feedback.location || 'Not Provided'}
                        </div>
                        <div className="mt-2">
                            <strong>Date:</strong> {new Date(feedback.date).toLocaleDateString() || 'Not Provided'}
                        </div>

                        <div className="mt-2">
                            <strong>Rating:</strong> {feedback.ratings || 'Not Provided'}
                        </div>

                        <div className="mt-4">
                            <div className="flex justify-center">
                                <img
                                    src={`http://localhost:3511/${feedback.feedbackImage[0]}`}  // Display the image
                                    alt="Feedback"
                                    className="w-48 h-auto object-cover"
                                />
                            </div>
                        </div>

                        <div className="mt-4 flex justify-end gap-3">
                            <Link
                                to={`/adminpage/update-feedback/${feedback._id}`}
                                className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain p-1 rounded-xl'
                            >
                                Update
                            </Link>
                            <button
                                onClick={() => openModal(feedback._id)}
                                className="bg-red-600 text-white py-1 px-4 rounded-xl"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <ConfirmDeleteModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onDelete={handleDelete}
                feedbackId={feedbackIdToDelete}
            />
        </div>
    );
};

export default Feedback;
