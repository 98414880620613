import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'

const useFeedBack = () => {
    const [data, setFeedBack] = useState([])
    const [loading, setLoading] = useState(true);
    const feedBack = async () => {

        try {
            const res = await fetch(BASE_URL + "allfeedback")
            const json = await res.json()
            const data = json?.feedbacks
            // console.log("data", data?.feedbacks)
            setFeedBack(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }

    }
    useEffect(() => {
        feedBack()
    }, [])
    return { data, loading }
}

export default useFeedBack
