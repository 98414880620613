import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'

const useGetCompantDetails = () => {
    const [data, setCompanyData] = useState([])
    const [loading, setLoading] = useState(true);

    const companyData = async () => {
        try {
            const res = await fetch(BASE_URL + "allcompany")
            const json = await res.json()
            const data = json?.data
            setCompanyData(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        companyData()
    }, [])
    return { data, loading }
}

export default useGetCompantDetails
