import { createBrowserRouter, Outlet, RouterProvider, useNavigation } from 'react-router-dom';
import './index.css'
import ContactUs from './components/ContactUs';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Blog from './components/Blog';
import About from './components/About';
import NavBar from './components/NavBar';
import Body from './components/Body';
import { useEffect, useState } from 'react';
import AuthRoutes from './routes/AuthRoutes';
import { Toaster } from 'react-hot-toast';
import Routes from './routes/Routes';
import AdminLayout from './routes/AdminLayout';
import Error from './components/subcomponents/Error';
import TermsCondition from './components/TermsCondition';
import Privacy from './components/Privacy';
import { disableReactDevTools } from "@fvilers/disable-react-devtools"
import PostPage from './components/PostPage';


if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}



function Main() {

  return (
    <div>
      <NavBar />
      <Outlet />
    </div>
  )
}

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Body />
      },
      {
        path: "/contact-us",
        element: <ContactUs />
      },
      {
        path: "/services",
        element: <Services />
      },
      {
        path: "/portfolio",
        element: <Portfolio />
      },
      {
        path: "/blog",
        element: <Blog />
      },
      {
        path: "/about-us",
        element: <About />
      },
      {
        path: "/terms&condition",
        element: <TermsCondition />
      },
      {
        path: "/privacy&policy",
        element: <Privacy />
      },
      {
        path: "/post/:id",
        element: <PostPage />
      },

      ...AuthRoutes,
      {
        path: '/',
        element: <AdminLayout />,
        children: [...Routes]
      },
    ],
    errorElement: <Error />
  }
])

function App() {
  return (

    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <RouterProvider router={appRouter} />
    </>
  );
}

export default App;
