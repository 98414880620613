import React from 'react';

const Seen = () => {
    return (
        <div className="py-16 md:py-32">
            <div className="text-center py-6 bg-[#1d2842]">
                <h1 className="text-lg md:text-2xl lg:text-3xl font-bold text-white inline-block">
                    You may have seen our work in…
                </h1>
            </div>
            <div className="py-10 md:py-20 bg-[#ffead3]">
                <div className="flex flex-wrap justify-center gap-4 md:gap-7">
                    <div className="bg-black w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full"></div>
                    <div className="bg-black w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full"></div>
                    <div className="bg-black w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full"></div>
                    <div className="bg-black w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full"></div>
                    <div className="bg-black w-20 h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full"></div>
                </div>
            </div>
        </div>
    );
};

export default Seen;
