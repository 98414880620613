import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import useLogin from '../customhooks/useLogin';


const Login = () => {
    const { handleLogin, error } = useLogin()

    const validationSchema = Yup.object({
        emailId: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character (e.g., Example@123)"
            )
    });

    return (
        <>
            <Formik
                initialValues={{
                    emailId: "",
                    password: ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="flex justify-center mt-20 px-4 sm:px-0">
                            <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                                <div className="text-center pb-3">
                                    <p className="text-2xl font-semibold text-gray-700 mb-2">Sign In</p>
                                </div>

                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Email</p>
                                    <label className="input flex items-center border border-gray-300 rounded-lg px-3 py-2 gap-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                            className="h-5 w-5 text-gray-400">
                                            <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                                            <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                                        </svg>
                                        <Field type="email" name="emailId" className="w-full outline-none text-gray-600" placeholder="Enter Email" />
                                    </label>
                                    <ErrorMessage name="emailId" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Password</p>
                                    <label className="input flex items-center border border-gray-300 rounded-lg px-3 py-2 gap-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                            className="h-5 w-5 text-gray-400">
                                            <path
                                                fillRule="evenodd"
                                                d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                                                clipRule="evenodd" />
                                        </svg>
                                        <Field type="password" name="password" className="w-full outline-none text-gray-600" placeholder="Enter Password" />
                                    </label>
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="text-center py-3">
                                    <button type="submit" disabled={isSubmitting} className="btn text-white bg-blue-500 px-4 py-2 rounded-lg hover:bg-blue-600">
                                        {isSubmitting ? "Logging in..." : "Login"}
                                    </button>
                                </div>

                                <p className="text-center">
                                    Don't have an account? <Link to="/register" className="text-blue-500 hover:underline">Register</Link>
                                </p>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </>
    )
}

export default Login
