import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const user = useSelector((store) => store.user.user);
    // console.log(user)
    // console.log("userType", userType)

    if (!user) {
        return <Navigate to="/unauthorized" />;
    }

    return children;
};

export default ProtectedRoute;
