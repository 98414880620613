import React, { useState, useEffect } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { motion } from 'framer-motion';
import useCompanyDetails from '../utils/customHooks/useFetchCompanyDetails';
import ContactUsModal from './ContactUsModal';
import Footer from './Footer'

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const { companyDetails } = useCompanyDetails();
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000);

        return () => clearTimeout(timer);
    }, [successMessage, errorMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Phone number must be exactly 10 digits';
        }
        if (!formData.message) newErrors.message = 'Message is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrorMessage("");
        setSuccessMessage("");

        try {
            const response = await fetch('http://localhost:3511/api/contactus/create-contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to send message: Server returned an error');
            }

            const result = await response.json();
            setFormData({ name: '', email: '', phone: '', message: '' });
            setSuccessMessage("Thanks for sharing your details! Our team will reach you soon.");
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage("Failed to send your message. Please try again.");
            setFormData({ name: '', email: '', phone: '', message: '' });

        }
    };

    return (
        <>
            <div className="relative w-full h-[500px]">
                <img
                    src="https://vivekkrishnan.com/wp-content/uploads/2022/07/Top-Wedding-Venues-in-Bangalore.jpg"
                    alt="Contact Us"
                    className="w-full h-full object-cover"
                />
                <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl md:text-2xl lg:text-3xl font-bold text-white text-center">
                    Let's Talk
                </h1>
            </div>

            <div>
                {errorMessage && (
                    <div className="mt-2 max-w-6xl mx-auto text-center text-lg bg-gray-200 text-red-600 shadow-lg rounded-lg p-4" role="alert">
                        <span className="font-bold">Something Went Wrong:</span> {errorMessage}
                    </div>
                )}
                {successMessage && (
                    <div className="mt-2 max-w-6xl mx-auto text-center text-lg bg-gray-200 text-green-600 shadow-lg rounded-lg p-4" role="alert">
                        <span className="font-bold">Success:</span> {successMessage}
                    </div>
                )}
            </div>

            <div className="flex flex-col items-center mt-8 space-y-8">
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ type: "spring", stiffness: 100, damping: 30, duration: 1 }}
                >
                    <div className="flex flex-wrap justify-center items-center gap-4">
                        <div className="flex flex-col justify-center items-center p-4 bg-white text-blue-950 shadow-md hover:shadow-lg transition-shadow cursor-pointer transform hover:bg-blue-950 group w-60 h-60">
                            <EmailIcon fontSize="large" className="text-blue-950 mb-2 group-hover:text-white" />
                            <h3 className="font-semibold group-hover:text-white">Email</h3>
                            <p className="text-center group-hover:text-white">{companyDetails?.company[0]?.emailId}</p>
                        </div>
                        <div className="flex flex-col justify-center items-center p-4 bg-white text-blue-950 shadow-md hover:shadow-lg transition-shadow cursor-pointer transform hover:bg-blue-950 group w-60 h-60">
                            <PhoneIcon fontSize="large" className="text-blue-950 mb-2 group-hover:text-white" />
                            <h3 className="font-semibold group-hover:text-white">Phone</h3>
                            <p className="text-center group-hover:text-white">{companyDetails?.company[0]?.phone}</p>
                        </div>
                        <div className="flex flex-col justify-center items-center p-4 bg-white text-blue-950 shadow-md hover:shadow-lg transition-shadow cursor-pointer transform hover:bg-blue-950 group w-60 h-60">
                            <LocationOnIcon fontSize="large" className="text-blue-950 mb-2 group-hover:text-white" />
                            <h3 className="font-semibold group-hover:text-white">Address</h3>
                            <p className="text-center group-hover:text-white">{companyDetails?.company[0]?.address}</p>
                        </div>
                    </div>
                </motion.div>
            </div>

            <motion.div initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.6, ease: "easeOut" }}>
                <div className="max-w-6xl mx-auto mt-8 bg-white p-8 shadow-lg">

                    <h1 className="text-xl md:text-2xl lg:text-2xl font-bold text-textmain">
                        ENQUIRIES, QUESTIONS, AND APPOINTMENTS
                    </h1>
                    <p className="text-gray-600 mb-4">
                        Fill in the form below and we will get back to you as soon as we can:
                    </p>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="w-full p-2 border border-blue-950 rounded"
                                    placeholder="Your Name"
                                    required
                                />
                                {errors.name && <p className="text-red-500">{errors.name}</p>}
                            </div>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full p-2 border border-blue-950 rounded"
                                    placeholder="Your Email"
                                    required
                                />
                                {errors.email && <p className="text-red-500">{errors.email}</p>}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="w-full p-2 border border-blue-950 rounded"
                                    placeholder="Your Phone Number"
                                    required
                                />
                                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                            </div>
                        </div>
                        <div>
                            <label className="block text-gray-700">Message</label>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                className="w-full p-2 border border-blue-950 rounded"
                                placeholder="Your Message"
                                required
                            ></textarea>
                            {errors.message && <p className="text-red-500">{errors.message}</p>}
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-950 text-white p-2 rounded transition-colors hover:bg-blue-800"
                        >
                            Send Message
                        </button>
                    </form>

                </div>
            </motion.div>

            {isModalOpen && (
                <ContactUsModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            )}
            <Footer />
        </>
    );
};

export default ContactUs;
