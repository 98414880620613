import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'

const useGetAllService = () => {
    const [service, setServices] = useState({})
    const [loading, setLoading] = useState(true);

    const handleFetchAllService = async () => {
        try {
            const res = await fetch(BASE_URL + "allservices")
            const json = await res.json()
            const data = json?.services
            // console.log(data)
            setServices(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleFetchAllService()
    }, [])

    return { service, loading }
}

export default useGetAllService
