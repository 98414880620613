import React from 'react'
import { FaArrowRight } from "react-icons/fa6";
import { MdOutlineArrowOutward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../utils/Helper';

const BlogComponent = ({ blog }) => {
    // console.log(blog)
    const truncateText = (description, length) => {
        if (description?.length > length - 1) {
            return description.slice(0, 140) + " ..."
        } else {
            return description
        }

    }
    return (
        <div className='px-4 sm:px-8 md:px-16 mt-14'>
            <div className='mb-5'>
                <h1 className='text-xl font-bold sm:text-2xl md:text-xl lg:text-2xl'>From the blog</h1>
            </div>
            <div className='flex flex-col gap-8 my-5'>
                {blog && blog?.slice(0, 5).map((data) => (

                    <div className='group hover:cursor-pointer border rounded-lg p-4 flex flex-col sm:flex-row justify-center items-center sm:gap-8 lg:gap-12 transition-transform duration-500 hover:bg-[#ffead3] hover:text-[#1D2842]'>
                        {data.images && data.images.length > 0 && (
                            <img className='w-full sm:w-48 bg-black border h-28 mb-4 sm:mb-0' src={IMAGE_BASE_URL + data.images[0]?.url} alt="Blog Thumbnail" />
                        )}
                        <div className='text-center sm:text-left'>
                            <h1 className='text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold'>
                                {data?.title}
                            </h1>
                            <p className='text-sm sm:text-md mt-2'>
                                {data?.subtitle}
                            </p>
                            <p className='text-sm sm:text-md mt-2'>
                                {truncateText(data?.content, 200)}
                            </p>
                        </div>
                        <div className='border p-3 rounded-xl transition-colors duration-500 group-hover:bg-[#1D2842] group-hover:text-white'>
                            <FaArrowRight />
                        </div>
                    </div>
                ))

                }

            </div>
            <div className='my-2'>
                <div className='float-end px-2 py-2 rounded-xl transition-all duration-600 bg-gradient-to-r from-[#1D2842] to-[#EBB882] '>
                    <Link to={`/blog`}>
                        <h1 className='p-2 text-white px-4 text-sm sm:text-md border border-transparent flex items-center  hover:border hover:border-white rounded-lg hover:text-white'>
                            View All Blogs
                            <MdOutlineArrowOutward className='' />
                        </h1>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default BlogComponent
