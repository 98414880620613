import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa6';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useGetCompantDetails from '../customhooks/useGetCompantDetails';
import { BASE_URL, IMAGE_BASE_URL } from '../utils/Helper';
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import toast from 'react-hot-toast';
import { removeUser } from '../utils/redux/slices/useSlices';

const NavBar = () => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { data } = useGetCompantDetails();
    const user = useSelector((store) => store.user.user)
    // console.log("user", user)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleLogout = async () => {
        try {
            const res = await axios.post(BASE_URL + "logout", {}, { withCredentials: true })
            localStorage.removeItem("token")
            dispatch(removeUser())
            navigate("/")
            return toast.success("Logged Out!")
        } catch (error) {
            console.error(error)
            return toast.error(error)
        }
    }

    const links = [
        { name: 'Home', path: '/' },
        { name: 'Services', path: '/services' },
        { name: 'Portfolio', path: '/portfolio' },
        { name: 'Blog', path: '/blog' },
        { name: 'About Us', path: '/about-us' },
        { name: 'Contact Us', path: '/contact-us' },
    ];

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="navbar bg-base-100 px-5 md:px-10 relative">
                <div className="navbar-start flex items-center">
                    <img
                        role='button'
                        className='w-16 shadow-md rounded-full transition-transform duration-300 hover:scale-110 hover:shadow-2xl'
                        src="WC_LOGO.png"
                        alt="Logo"
                    />
                    <Link to={`/`} className="text-xl md:text-3xl lg:text-3xl ml-2 whitespace-nowrap text-[#1d2842]">Wedding Cluster</Link>

                </div>
                <div className="navbar-center hidden md:flex ">
                    <ul className="menu menu-horizontal px-1 gap-6">
                        {links.map((link) => (
                            <li key={link.name}>
                                <Link
                                    to={link.path}
                                    className={`relative px-2 py-1 group text-[#1d2842] ${location.pathname === link.path ? 'text-[#1d2842] text-xl underline' : 'text-xl'
                                        } hover:bg-clip-text hover:text-transparent`}
                                    style={{
                                        backgroundImage:
                                            "linear-gradient(76deg, rgba(29,40,66,1) 22%, rgba(235,184,130,1) 100%)",
                                        WebkitTextFillColor: location.pathname === link.path ? 'transparent' : '',
                                        WebkitBackgroundClip: 'text',
                                    }}
                                >
                                    {link.name}
                                    <span
                                        className={`absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-[#1d2842] to-[#ebb882] 
                                    transform ${location.pathname === link.path ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300 ease-in-out 
                                    group-hover:scale-x-100`}
                                        style={{
                                            backgroundImage: 'linear-gradient(76deg, rgba(29,40,66,1) 22%, rgba(235,184,130,1) 100%)',
                                        }}
                                    ></span>
                                </Link>
                            </li>

                        ))}
                    </ul>
                </div>
                {user && (
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img
                                    alt="Tailwind CSS Navbar component"
                                    src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
                            </div>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                            <li>
                                <Link to={`/userprofile`} className="justify-between">
                                    Profile
                                    <span className="badge">New</span>
                                </Link>
                            </li>
                            {/* <li><a>Settings</a></li> */}
                            <li>
                                <Link to={`/dashbord`}>
                                    Dashbord
                                </Link>
                            </li>
                            <li><Link onClick={handleLogout}>Logout</Link></li>
                        </ul>
                    </div>

                )}

                <div className="navbar-end md:hidden">
                    <button
                        onClick={toggleMenu}
                        className="text-3xl"
                        aria-expanded={isMenuOpen}
                        aria-label="Toggle navigation menu"
                    >
                        {isMenuOpen ? '✖' : '☰'}
                    </button>
                </div>

            </div>

            {isMenuOpen && (
                <div className="absolute flex justify-end items-end right-0 bg-base-100 shadow-lg rounded-lg p-4 md:hidden z-20">
                    <ul className="flex flex-col items-start">
                        {links.map((link) => (
                            <li key={link.name} className="py-2">
                                <Link
                                    to={link.path}
                                    onClick={() => setIsMenuOpen(false)}
                                    className={`relative group text-xl ${location.pathname === link.path ? 'text-[#1d2842] text-lg underline' : 'text-lg'}
                                hover:bg-clip-text hover:text-transparent`}
                                    style={{
                                        backgroundImage:
                                            "linear-gradient(76deg, rgba(29,40,66,1) 22%, rgba(235,184,130,1) 100%)",
                                        WebkitTextFillColor: location.pathname === link.path ? 'transparent' : '',
                                        WebkitBackgroundClip: 'text',
                                    }}
                                >
                                    {link.name}
                                    <span
                                        className={`absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-[#1d2842] to-[#ebb882] 
                                    transform ${location.pathname === link.path ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300 ease-in-out 
                                    group-hover:scale-x-100`}
                                        style={{
                                            backgroundImage: 'linear-gradient(76deg, rgba(29,40,66,1) 22%, rgba(235,184,130,1) 100%)',
                                        }}
                                    ></span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="px-4 sm:px-8 md:px-16 lg:px-24 bg-textmain text-white py-3">
                {data && data.length > 0 && (
                    <aside className="flex flex-col md:flex-row justify-between items-center gap-4 text-center md:text-left">
                        <div className="flex flex-col md:flex-row gap-4 md:gap-6 items-center">
                            <p className="text-sm flex items-center gap-1">
                                <MdEmail />
                                <span>{data[0]?.emailId}</span>
                            </p>
                            <p className="text-sm flex items-center gap-1">
                                <IoCall />
                                <span>{data[0]?.phone}</span>
                            </p>
                            <p className="text-sm flex items-center gap-1">
                                <FaLocationDot />
                                <span>{data[0]?.address}</span>
                            </p>
                        </div>

                        <div className="flex justify-center md:justify-end space-x-4">

                            <Link to="https://www.instagram.com/weddingcluster_official/" target="_blank">
                                <FaInstagram className="text-pink-600 hover:text-pink-700 text-xl md:text-2xl" />
                            </Link>
                            <Link to="https://www.facebook.com/weddingcluster/" target="_blank">
                                <FaFacebook className="text-blue-800 hover:text-blue-900 text-xl md:text-2xl" />
                            </Link>
                            <Link to="https://www.linkedin.com/in/wedding-cluster-a9673a2b9/" target="_blank">
                                <FaLinkedin className="text-blue-600 hover:text-blue-700 text-xl md:text-2xl" />
                            </Link>
                            <Link to="https://www.youtube.com/@weddingcluster1" target="_blank">
                                <FaYoutube className="text-red-600 hover:text-red-700 text-xl md:text-2xl" />
                            </Link>

                        </div>
                    </aside>
                )}
            </div>

        </>
    );
}

export default NavBar;
