import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddFeedback = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object({
    brideName: Yup.string().required('Bride name is required.'),
    groomName: Yup.string().required('Groom name is required.'),
    location: Yup.string().required('Location is required.'),
    date: Yup.date().required('Date is required.'),
    description: Yup.string().required('Description is required.'),
    ratings: Yup.number().min(1, 'Ratings must be at least 1').max(5, 'Ratings must be at most 5').required('Ratings are required.'),
    feedbackImage: Yup.mixed()
      .required('At least one photo is required.')
      .test('fileSize', 'File size must be less than 5MB each.', (value) => {
        return value && Array.from(value).every(file => file.size <= 1024 * 1024 * 5);
      })
      .test('fileType', 'Only JPEG, JPG, and PNG files are allowed.', (value) =>
        value && Array.from(value).every(file => ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type))
      ),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('brideName', values.brideName);
      formData.append('groomName', values.groomName);
      formData.append('location', values.location);
      formData.append('date', values.date);
      formData.append('description', values.description);
      formData.append('ratings', values.ratings);

      if (values.feedbackImage) {
        Array.from(values.feedbackImage).forEach((file) => {
          formData.append('feedbackImage', file);
        });
      }

      const response = await axios.post('http://localhost:3511/api/feedback/create-feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setSuccessMessage('Feedback created successfully!');
        setErrorMessage('');
        navigate('/adminpage/feedbacks'); 
        setErrorMessage(response.data.message || 'Failed to create feedback.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error creating feedback:', error);
      setErrorMessage('Error creating the feedback.');
      setSuccessMessage('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center mt-10">
      <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
        <button onClick={() => navigate(-1)} className="p-2 m-2 w-20 text-white rounded-lg bg-blue-950">Back</button>

        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Create Feedback</h2>

        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
        {successMessage && <p className="text-green-600 text-center">{successMessage}</p>}

        <Formik
          initialValues={{
            brideName: '',
            groomName: '',
            location: '',
            date: '',
            description: '',
            ratings: '',
            feedbackImage: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <label htmlFor="brideName" className="block text-sm font-medium text-gray-700">Bride Name</label>
                <Field
                  id="brideName"
                  name="brideName"
                  placeholder="Enter bride's name"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="brideName" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="groomName" className="block text-sm font-medium text-gray-700">Groom Name</label>
                <Field
                  id="groomName"
                  name="groomName"
                  placeholder="Enter groom's name"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="groomName" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
                <Field
                  id="location"
                  name="location"
                  placeholder="Enter location"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="location" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <Field
                  id="date"
                  name="date"
                  type="date"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="date" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  placeholder="Enter feedback description"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="description" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="ratings" className="block text-sm font-medium text-gray-700">Ratings</label>
                <Field
                  id="ratings"
                  name="ratings"
                  type="number"
                  min="1"
                  max="5"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="ratings" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="feedbackImage" className="block text-sm font-medium text-gray-700">Feedback Image</label>
                <input
                  id="feedbackImage" 
                  name="feedbackImage" 
                  type="file"
                  onChange={(event) => setFieldValue('feedbackImage', event.currentTarget.files)}
                  multiple
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="feedbackImage" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4 text-center">
                <button 
                  type="submit" 
                  className="bg-blue-950 text-white py-2 px-6 rounded-md hover:bg-blue-950"
                  disabled={isSubmitting} 
                >
                  {isSubmitting ? 'Submitting...' : 'Create Feedback'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddFeedback;

