import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const AddSubPortfolio = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(3, 'Title must be at least 3 characters long.')
      .max(50, 'Title must be no longer than 50 characters.')
      .required('Title is required.'),
    subtitle: Yup.string()
      .min(3, 'Subtitle must be at least 3 characters long.')
      .max(100, 'Subtitle must be no longer than 100 characters.')
      .required('Subtitle is required.'),
    description: Yup.string()
      .max(100, 'Description must be no longer than 100 characters.')
      .required('Description is required.'),
  });

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('subtitle', values.subtitle);
      formData.append('description', values.description);

      selectedFiles.forEach((file) => {
        formData.append('photos', file); 
      });

      const response = await fetch("http://localhost:3511/api/subportfolio/create-subportfolio", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      const data = await response.json();

      if (data.success) {
        setSuccessMessage('SubPortfolio created successfully!');
        setErrorMessage('');
        setSelectedFiles([]);
      } else {
        setErrorMessage(data.message || 'Failed to create SubPortfolio.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error creating SubPortfolio:', error);
      setErrorMessage('Error creating the SubPortfolio.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <div className="flex justify-center mt-10">
        <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
          <button onClick={() => navigate(-1)} className="p-2 m-2 w-20 text-white rounded-lg bg-blue-950">Back</button>
          <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Create SubPortfolio</h2>

          {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
          {successMessage && <p className="text-green-600 text-center">{successMessage}</p>}

          <Formik
            initialValues={{
              title: '',
              subtitle: '',
              description: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="mb-4">
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                  <Field id="title" name="title" placeholder="Enter title" className="mt-2 p-2 border w-full rounded-md" />
                  <ErrorMessage name="title" component="div" className="text-red-600 text-sm" />
                </div>

                <div className="mb-4">
                  <label htmlFor="subtitle" className="block text-sm font-medium text-gray-700">Subtitle</label>
                  <Field id="subtitle" name="subtitle" placeholder="Enter subtitle" className="mt-2 p-2 border w-full rounded-md" />
                  <ErrorMessage name="subtitle" component="div" className="text-red-600 text-sm" />
                </div>

                <div className="mb-4">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                  <Field as="textarea" id="description" name="description" placeholder="Enter description" className="mt-2 p-2 border w-full rounded-md" />
                  <ErrorMessage name="description" component="div" className="text-red-600 text-sm" />
                </div>

                <div className="mb-4">
                  <label htmlFor="photos" className="block text-sm font-medium text-gray-700">Photos</label>
                  <input
                    id="photos"
                    name="photos"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    className="mt-2 p-2 border w-full rounded-md"
                  />
                </div>

                <div className="mb-4">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="flex items-center space-x-2 mt-2">
                      <span>{file.name}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>

                <div className="mb-4 text-center">
                  <button type="submit" className="bg-blue-950 text-white py-2 px-6 rounded-md hover:bg-blue-950">Create SubPortfolio</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddSubPortfolio;
