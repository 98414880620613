import React from 'react'
import { Link } from 'react-router-dom'
import { IMAGE1, IMAGE2, IMAGE3, IMAGE4, IMAGE5 } from '../../utils/constants'

const WhatWeAre = () => {
    return (
        <div className='px-4 sm:px-8 md:px-16 mt-20'>
            <section className="">
                <div className=" mb-3">
                    <h1 className='text-xl md:text-2xl lg:text-3xl font-bold text-textmain'>
                        What makes us stand out!
                    </h1>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="col-span-1  p-4">
                        <div className="col-span-1">
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <Link to="">
                                    <img src={IMAGE1} alt="Extensive Venue Options" className="w-full object-cover shadow-xl" />
                                </Link>
                                <div className="p-5">
                                    <h6 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882]">Extensive Venue Options</h6>
                                    <p className="text-gray-600 mt-2">We offer a wide range of venues, allowing you to find the perfect setting with ease. Our platform makes it easy to compare venues and book your ideal location quickly.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 my-3">
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <Link to="">
                                    <img src={IMAGE2} alt="Flexible Booking Options" className="w-full object-cover shadow-xl" />
                                </Link>
                                <div className="p-5">
                                    <h6 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882]">Flexible Booking Options.</h6>
                                    <p className="text-gray-600 mt-2">Whether you are planning months in advance or need a last-minute venue, we have flexible booking options at our platform to accommodate your schedule. Let us know your timeline and we will arrange the perfect place for your wedding.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 mt-36  p-4">
                        <div className="col-span-1">
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <Link to="">
                                    <img src={IMAGE3} alt="Extensive Venue Options" className="w-full object-cover shadow-xl" />
                                </Link>
                                <div className="p-5">
                                    <h6 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882]">Professional wedding planners.</h6>
                                    <p className="text-gray-600 mt-2">We have hired certified professional wedding planners. Our Team has years of experience in event management & wedding planning to provide best on demand wedding services.  Our team is friendly and adaptive in nature to understand client requirements and desires for the wedding.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-span-1 p-4">
                        <div className="col-span-1">
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <Link to="">
                                    <img src={IMAGE4} alt="Destination weddings." className="w-full object-cover shadow-xl" />
                                </Link>
                                <div className="p-5">
                                    <h6 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882]">Destination weddings.</h6>
                                    <p className="text-gray-600 mt-2">We offer customized destination wedding packages as per your taste, style and budget. Our team will bring your vision to life, whether you prefer a beach location or hill station we will arrange a dream wedding for you anywhere in India.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 my-3">
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <Link to="">
                                    <img src={IMAGE5} alt="All Services at one Platform" className="w-full object-cover shadow-xl" />
                                </Link>
                                <div className="p-5">
                                    <h6 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882]">All Services at one Platform</h6>
                                    <p className="text-gray-600 mt-2">We offer Best wedding services in Hubli, Dharwad, Harihar, Belgaum & all over karnataka.  Our broad selection of venues, knowledgeable staff, variety of vendors, prompt delivery, and dedication to our job allow us to maintain a solid position in this competitive wedding industry..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>




        </div>
    )
}

export default WhatWeAre
