import React from 'react'
import FirstBlog from './subcomponents/FirstBlog'
import useGetBlogs from '../customhooks/useGetBlogs'
import AllBlogs from './subcomponents/AllBlogs'
import Footer from './Footer'
import useGetAllService from '../customhooks/useGetAllService'

const Blog = () => {

    const { blog } = useGetBlogs()
    // console.log("blog", blog)
    const firstBlog = blog?.data?.[0]
    // console.log("firstBlog", firstBlog)
    const { service, loading } = useGetAllService();
    console.log(service)

    if (!service) return null
    return (
        <>
            <div className="px-4 sm:px-8 md:px-16 lg:px-24">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                    <div className="p-4">
                        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] inline-block">
                            Our Blogs
                        </h1>
                        <p className='py-5 text-textmain text-base sm:text-lg md:text-lg'>
                            I LOVED this book! This is the definition of a perfect wedding planner! Jessica goes into so much detail… She covers everything plus some and then plus some more!
                        </p>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="bg-yellow-100 text-black text-sm md:text-base rounded-lg p-6 w-full md:w-10/12 lg:w-9/12 mx-auto mt-4 shadow-md">
                            “I LOVED this book! This is the definition of a perfect wedding planner! Jessica goes into so much detail… She covers everything plus some and then plus some more!”
                        </div>
                    </div>
                </div>

                <FirstBlog blog={firstBlog} />
                <AllBlogs blog={blog} service={service} />
            </div>
            <Footer />
        </>

    )
}

export default Blog
