import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from '../../../utils/modals/ConfirmDeleteModal';

const Subportfolio = () => {
    const [subportfolios, setSubportfolios] = useState([]);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subportfolioIdToDelete, setSubportfolioIdToDelete] = useState(null);
    const [imageIndexToDelete, setImageIndexToDelete] = useState(null);
    console.log('description', subportfolios[0]?.description);
    console.log(subportfolios);

    useEffect(() => {
        const fetchSubportfolios = async () => {
            try {
                const response = await axios.get('http://localhost:3511/api/subportfolio/getall-subportfolio');
                const subportfoliosWithToggle = response.data.data.map(item => ({
                    ...item,
                    showFullDescription: false,
                }));
                setSubportfolios(subportfoliosWithToggle);
            } catch (err) {
                setError('Error fetching subportfolios');
            }
        };

        fetchSubportfolios();
    }, []);

    const handleDelete = async () => {
        try {
            await axios.delete(`http://localhost:3511/api/subportfolio/delete-subportfolio/${subportfolioIdToDelete}`);
            setSubportfolios(subportfolios.filter(subportfolio => subportfolio._id !== subportfolioIdToDelete));
            setIsModalOpen(false);
        } catch (err) {
            console.error('Error deleting subportfolio:', err);
        }
    };

    const openModal = (id) => {
        setSubportfolioIdToDelete(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSubportfolioIdToDelete(null);
    };

    const toggleDescription = (id) => {
        setSubportfolios(subportfolios.map(subportfolio =>
            subportfolio._id === id
                ? { ...subportfolio, showFullDescription: !subportfolio.showFullDescription }
                : subportfolio
        ));
    };

    const handleDeleteImage = async (subportfolioId, imageIndex) => {
        console.log('subportfolioid , imageIndex', subportfolioId, imageIndex)
        try {
            await axios.delete(`http://localhost:3511/api/subportfolio/delete-image/${subportfolioId}/${imageIndex}`);

            const updatedSubportfolios = subportfolios.map(subportfolio => {
                if (subportfolio._id === subportfolioId) {
                    const updatedImages = [...subportfolio.images];
                    updatedImages.splice(imageIndex, 1);
                    return { ...subportfolio, images: updatedImages };
                }
                return subportfolio;
            });

            setSubportfolios(updatedSubportfolios);
        } catch (err) {
            console.error('Error deleting image:', err);
        }
    };

    return (
        <div className=" mt-5">
            <div className="mb-4 flex justify-end">
                <Link to={'/adminpage/add-subportfolio'} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain p-1 rounded-xl '>+ Add Subportfolio</Link>
            </div>
            {error && <p>{error}</p>}
            <p className='text-xl font-bold mb-3'>All Subportfolios</p>
            <div className="flex flex-col  flex-wrap gap-3">
                {subportfolios.map((subportfolio) => (
                    <div key={subportfolio._id} className="border p-4 rounded-lg">
                        <h3 className="text-xl font-semibold">{subportfolio.title || 'No Title'}</h3>
                        <p className="text-lg text-gray-700">{subportfolio.subtitle || 'No Subtitle'}</p>

                        <div className="mt-2">
                            {subportfolio.description ? (
                                subportfolio.showFullDescription ? (
                                    <p>{subportfolio.description}</p>
                                ) : (
                                    <p>{subportfolio.description.slice(0, 100)}...</p>
                                )
                            ) : (
                                <p>No Description</p>
                            )}
                            {subportfolio.description && (
                                <button
                                    className="bg-gray-300 rounded-sm mt-2 px-4 py-1"
                                    onClick={() => toggleDescription(subportfolio._id)}
                                >
                                    {subportfolio.showFullDescription ? 'Show Less' : 'Show More'}
                                </button>
                            )}
                        </div>

                        <div className="mt-4">
                            {subportfolio.images && subportfolio.images.length > 0 ? (
                                subportfolio.images.map((image, index) => (
                                    <div key={index} className="relative">
                                        <img
                                            src={image}
                                            alt="Subportfolio"
                                            className="w-24 h-auto m-2"
                                        />
                                        <button
                                            className="absolute top-0 right-0 bg-red-600 text-white p-1 rounded-full"
                                            onClick={() => handleDeleteImage(subportfolio._id, index)}
                                        >
                                            X
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p>No Images</p>
                            )}
                        </div>

                        <div className="mt-4 flex justify-end gap-3">
                            <Link to={`/adminpage/update-subportfolio/${subportfolio._id}`} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain p-1 rounded-xl'>
                                Update
                            </Link>
                            <button onClick={() => openModal(subportfolio._id)} className="bg-red-600  text-white py-1 px-4 rounded-lg">
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <ConfirmDeleteModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onDelete={handleDelete}
                itemId={subportfolioIdToDelete}
            />
        </div>
    );
};

export default Subportfolio;
