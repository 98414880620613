import React from 'react'
import { MdOutlineAccessTimeFilled } from 'react-icons/md'
import { Link } from 'react-router-dom'
import useGetAllService from '../../customhooks/useGetAllService';
import { IMAGE_BASE_URL } from '../../utils/Helper';

const AllBlogs = ({ blog, service }) => {
    // console.log("All blogs", blog)
    const truncateText = (description, length) => {
        if (description?.length > length - 1) {
            return description.slice(0, 190) + " ..."
        } else {
            return description
        }
    }
    console.log("service", service)
    if (!service) return null
    return (
        <div>
            <h3 className="text-lg font-bold text-gray-800 mb-4 mt-20">Recent Blogs</h3>
            <div className="grid grid-cols-1 lg:grid-cols-[3fr_1fr] gap-4 ">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {blog && blog.data?.sort((a, b) => b._id - a._id).slice(1).map((data, index) => (
                        <Link to={`/post/${data?._id}`} key={index}>
                            <div className="bg-base-100 w-full hover:shadow-xl">
                                <figure>
                                    {data?.images?.length > 0 && (
                                        <img
                                            src={IMAGE_BASE_URL + data.images[0].url}
                                            alt={`${data.title} image`}
                                            className="object-cover w-full h-32 sm:h-40 md:h-48 lg:h-64 shadow-md"
                                        />
                                    )}
                                </figure>
                                <div className="card-body">
                                    <p className="text-sm text-gray-400 flex items-center gap-1">
                                        <MdOutlineAccessTimeFilled />
                                        <span>{new Date(data?.date).toLocaleDateString('en-IN', {
                                            day: 'numeric',
                                            month: 'long',
                                            year: 'numeric',
                                        })}</span>
                                    </p>
                                    <p className="text-textmain">{(data?.serviceName).toUpperCase()}</p>

                                    <h2 className="card-title text-center pb-2 text-textmain">
                                        {data.title || "Blog Title"}
                                    </h2>
                                    <p className="text-sm text-neutral-500">{truncateText(data.content, 150)}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className=" p-6 rounded-lg ">
                    <h3 className="text-lg font-bold text-gray-800 mb-4">Services We Provide</h3>

                    {service && service.length > 0 ? (
                        <div className="space-y-6">
                            {service.map((data, index) => (
                                <>
                                    <Link to={`/services`}>
                                        <div key={index} className="flex justify-between items-center gap-4 my-2 p-4  rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200">

                                            <p className="text-md font-semibold text-gray-700">{data?.name}</p>
                                            <img src={IMAGE_BASE_URL + data?.photoUrl} alt={data?.name} className="w-20 h-20  object-cover border border-gray-200" />
                                        </div>
                                    </Link>

                                </>

                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-500">No services available at the moment.</p>
                    )}

                    <p className="mt-6 text-gray-600">This section can include additional information or widgets.</p>
                </div>

            </div>
        </div>




    )
}

export default AllBlogs
