import React from 'react'

const Pageination = ({ blog, page, setPAge }) => {
    const totalPage = Math.ceil(blog?.length / 10)

    const setPageAction = (select) => {
        if (select >= 0 && select <= totalPage && select !== page) {
            setPAge(select)
        }
    }

    return (
        <>
            <div className='my-10'>

                {blog?.length > 0 && (
                    <div className=' text-center'>
                        <span className={page <= 1 ? "bg-white text-white" : "bg-black text-white"} onClick={() => setPageAction(page - 1)}>◀️</span>
                        {[...Array(totalPage)].map((_, i) => (
                            <span className={`p-2 m-2 border ${page === i + 1 ? "bg-blue-500" : "bg-white"}`} onClick={() => setPageAction(i + 1)}>{i + 1}</span>
                        ))}
                        <span className={page >= totalPage ? "bg-white text-white" : "bg-black text-white"} onClick={() => setPageAction(page + 1)}>▶️</span>
                    </div>
                )}
            </div >

        </>

    )
}

export default Pageination
