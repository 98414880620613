import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const UpdateFeedback = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [feedbackDetails, setFeedbackDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  console.log('feedback', feedbackDetails);

  useEffect(() => {
    const fetchFeedbackDetails = async () => {
      try {
        const response = await axios.get(`http://localhost:3511/api/feedback/getfeedback-Byid/${id}`);
        console.log(response); // Log the response to check the data
        if (response.data && response.data.data) {
          setFeedbackDetails(response.data.data);
        } else {
          setErrorMessage('No feedback found.');
        }
      } catch (err) {
        setErrorMessage('Error fetching feedback details');
      }
    };

    fetchFeedbackDetails();
  }, [id]);

  const validationSchema = Yup.object({
    brideName: Yup.string()
      .min(3, 'Bride name must be at least 3 characters long.')
      .max(50, 'Bride name must be no longer than 50 characters.')
      .required('Bride name is required.'),
    groomName: Yup.string()
      .min(3, 'Groom name must be at least 3 characters long.')
      .max(50, 'Groom name must be no longer than 50 characters.')
      .required('Groom name is required.'),
    description: Yup.string().required('Description is required.'),
    location: Yup.string().required('Location is required.'),
    ratings: Yup.number()
      .min(1, 'Rating must be at least 1')
      .max(5, 'Rating must be at most 5')
      .required('Rating is required.'),
    date: Yup.date().required('Date is required.'),
    photos: Yup.mixed()
      .notRequired()
      .test('fileSize', 'Each file must be less than 5MB.', (value) => {
        return !value || Array.from(value).every(file => file.size <= 5 * 1024 * 1024);
      })
      .test('fileType', 'Only JPEG, JPG, and PNG files are allowed.', (value) =>
        !value || Array.from(value).every(file => ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type))
      ),
  });

  const handleSubmit = async (values) => {
    const formData = new FormData();

    formData.append('brideName', values.brideName);
    formData.append('groomName', values.groomName);
    formData.append('description', values.description);
    formData.append('location', values.location);
    formData.append('ratings', values.ratings);
    formData.append('date', values.date);

    if (values.photos && values.photos.length > 0) {
      Array.from(values.photos).forEach((file) => {
        formData.append('photos', file);
      });
    }

    try {
      const response = await fetch(`http://localhost:3511/api/feedback/update-feedback-byId/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
        body: formData,
        credentials: 'include',
      });

      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Feedback updated successfully!');
        setErrorMessage('');
      } else {
        setErrorMessage(data.message || 'Failed to update feedback.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error updating feedback:', error);
      setErrorMessage('Error updating the feedback.');
      setSuccessMessage('');
    }
  };

  if (!feedbackDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex justify-center mt-10">
      <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
        <button onClick={() => navigate(-1)} className="p-2 m-2 w-20 text-white rounded-lg bg-blue-950">Back</button>

        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Update Feedback</h2>

        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
        {successMessage && <p className="text-green-600 text-center">{successMessage}</p>}

        <Formik
          enableReinitialize
          initialValues={{
            brideName: feedbackDetails?.brideName || '',
            groomName: feedbackDetails?.groomName || '',
            description: feedbackDetails?.description || '',
            location: feedbackDetails?.location || '',
            ratings: feedbackDetails?.ratings || '',
            date: feedbackDetails?.date || '',
            photos: [],  // Initialize as an empty array, handle file uploads separately
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="mb-4">
                <label htmlFor="brideName" className="block text-sm font-medium text-gray-700">Bride Name</label>
                <Field
                  id="brideName"
                  name="brideName"
                  placeholder="Enter bride's name"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="brideName" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="groomName" className="block text-sm font-medium text-gray-700">Groom Name</label>
                <Field
                  id="groomName"
                  name="groomName"
                  placeholder="Enter groom's name"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="groomName" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="description" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
                <Field
                  id="location"
                  name="location"
                  placeholder="Enter location"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="location" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="ratings" className="block text-sm font-medium text-gray-700">Ratings (1-5)</label>
                <Field
                  id="ratings"
                  name="ratings"
                  type="number"
                  min="1"
                  max="5"
                  placeholder="Enter rating"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="ratings" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <Field
                  id="date"
                  name="date"
                  type="date"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="date" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="photos" className="block text-sm font-medium text-gray-700">Upload Photos</label>
                <input
                  id="photos"
                  name="photos"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => setFieldValue('photos', e.currentTarget.files)}
                  className="mt-2 p-2 border w-full rounded-md"
                />
                {values.photos && values.photos.length > 0 && (
                  <div className="mt-2">
                    {Array.from(values.photos).map((file, index) => (
                      <p key={index} className="text-gray-700">{file.name}</p>
                    ))}
                  </div>
                )}
                <ErrorMessage name="photos" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4 flex justify-center">
                <button
                  type="submit"
                  className="bg-blue-950 text-white py-2 px-4 rounded-lg"
                >
                  Update Feedback
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateFeedback;
