import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from '../../../utils/modals/ConfirmDeleteModal';

const CreateService = () => {
    const [services, setServices] = useState([]);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [serviceIdToDelete, setServiceIdToDelete] = useState(null);

    console.log('service details', services);


    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get('http://localhost:3511/api/services/getall-service');
                setServices(response.data.services);
            } catch (err) {
                setError('Error fetching services');
            }
        };

        fetchServices();
    }, []);

    const handleDelete = async () => {
        try {
            await axios.delete(`http://localhost:3511/api/services/deleteservice-byId/${serviceIdToDelete}`);
            setServices(services.filter(service => service._id !== serviceIdToDelete));
            setIsModalOpen(false);
        } catch (err) {
            console.error('Error deleting service:', err);
        }
    };

    const openModal = (id) => {
        setServiceIdToDelete(id);
        setIsModalOpen(true);
        console.log('service id', id)
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setServiceIdToDelete(null);
    };

    const toggleDescription = (id) => {
        setServices(services.map(service =>
            service._id === id ? { ...service, showFullDescription: !service.showFullDescription } : service
        ));
    };

    return (
        <div className="ml- mt-5">
            <div className='mb-4 flex justify-end'>
                <Link to={'/adminpage/add-service'} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain p-1 rounded-xl '>+ Add Service</Link>
            </div>
            {error && <p>{error}</p>}
            <div className="flex   flex-wrap gap-3">
                <p className='text-xl font-bold'>All Services</p>
                {services.map((service) => (
                    <div key={service._id} className="border p-4 rounded-lg ">
                        <h3 className="text-xl font-semibold ">{service.name}</h3>
                        <p className="text-lg ">{service.description1 || 'No Description'}</p>

                        <div className="mt-2">
                            {service.showFullDescription ? (
                                <>
                                    <strong>Description 1:</strong> {service.description1}<br />
                                    <strong>Description 2:</strong> {service.description2}
                                </>
                            ) : (
                                <>
                                    <strong>Description 1:</strong> {service.description1.slice(0, 100)}...<br />
                                    <strong>Description 2:</strong> {service.description2.slice(0, 100)}...
                                </>
                            )}
                            <button
                                className='bg-gray-300 rounded-sm mt-2 p-1'
                                onClick={() => toggleDescription(service._id)}
                            >
                                {service.showFullDescription ? 'Show Less' : 'Show More'}
                            </button>
                        </div>

                        <div className="mt-4">
                            <div className="flex justify-center">
                                <img
                                    src={service.photoUrl}
                                    alt="Service"
                                    className="w-48 h-auto object-cover"
                                />
                            </div>
                        </div>

                        <div className="mt-4 flex justify-end gap-3">
                            <Link
                                to={`/adminpage/update-service/${service._id}`}
                                className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain p-1 rounded-xl '
                            >
                                Update
                            </Link>
                            <button
                                onClick={() => openModal(service._id)}
                                className="bg-red-600 text-white py-1 px-4 rounded-xl"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <ConfirmDeleteModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onDelete={handleDelete}
                serviceId={serviceIdToDelete}
            />
        </div>
    );
};

export default CreateService;
