import React from 'react'
import useGetCompantDetails from '../../customhooks/useGetCompantDetails'
import { IMAGE_BASE_URL } from '../../utils/Helper';

const Counts = () => {
    const { data } = useGetCompantDetails()

    if (!data) return <h1 className="text-center text-lg sm:text-xl">Loading...</h1>;

    return (
        <div className="stats shadow flex flex-wrap md:flex-nowrap gap-4 relative py-10">
            <div className="stat flex-1 min-w-full md:min-w-0 relative">
                <div className=" text-secondary">Wedding Cluster</div>
                <div className="stat-figure text-secondary">
                    <div className="avatar online relative">
                        <div className="w-16 rounded-full">
                            {data && data.length > 0 && (
                                <img src={IMAGE_BASE_URL + data[0].image} alt="Wedding" />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="stat flex-1 min-w-[120px] md:min-w-0 place-items-center">
                <div className="stat-title">Weddings</div>
                <div className="stat-value">30</div>
            </div>

            <div className="stat flex-1 min-w-[120px] md:min-w-0 place-items-center">
                <div className="stat-title">Venues</div>
                <div className="stat-value">9</div>
                <div className="stat-desc text-secondary"></div>
            </div>

            <div className="stat flex-1 min-w-[120px] md:min-w-0 place-items-center">
                <div className="stat-title">Location</div>
                <div className="stat-value">3 +</div>
                <div className="stat-desc"></div>
            </div>

            <div className="stat flex-1 min-w-[120px] md:min-w-0 place-items-center">
                <div className="stat-title">Services</div>
                <div className="stat-value">30 +</div>
                <div className="stat-desc"></div>
            </div>
        </div>


    )
}

export default Counts
