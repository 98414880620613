import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const UpdateSubportfolio = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [subportfolioDetails, setSubportfolioDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchSubportfolio = async () => {
      try {
        const response = await axios.get(`http://localhost:3511/api/subportfolio/get-subportfolio-byId/${id}`);
        setSubportfolioDetails(response.data.data);
        setFiles(response.data.data.images || []);
        setLoading(false);
      } catch (err) {
        setErrorMessage('Error fetching subportfolio details');
        setLoading(false); 
      }
    };

    fetchSubportfolio();
  }, [id]);

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(3, 'Title must be at least 3 characters long.')
      .max(50, 'Title must be no longer than 50 characters.')
      .required('Title is required.'),
    subtitle: Yup.string()
      .min(3, 'Subtitle must be at least 3 characters long.')
      .max(100, 'Subtitle must be no longer than 100 characters.')
      .required('Subtitle is required.'),
    description: Yup.string().required('Description is required.'),
    images: Yup.mixed()
      .notRequired()
      .test('fileSize', 'Each file must be less than 5MB.', (value) => {
        return !value || Array.from(value).every(file => file.size <= 5 * 1024 * 1024);
      })
      .test('fileType', 'Only JPEG, JPG, and PNG files are allowed.', (value) =>
        !value || Array.from(value).every(file => ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type))
      ),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('subtitle', values.subtitle);
    formData.append('description', values.description);

    if (files.length > 0) {
      files.forEach((file) => {
        formData.append('images', file);
      });
    }

    try {
      const response = await axios.put(
        `http://localhost:3511/api/subportfolio/update-subportfolio/${id}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.success) {
        setSuccessMessage('Subportfolio updated successfully!');
        setErrorMessage('');
        resetForm(); 
      } else {
        setErrorMessage(response.data.message || 'Failed to update subportfolio.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error updating subportfolio:', error);
      setErrorMessage(error.response?.data?.message || 'Error updating the subportfolio.');
      setSuccessMessage('');
    }
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center mt-10">
      <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
        <button onClick={() => navigate(-1)} className="p-2 m-2 w-20 text-white rounded-lg bg-blue-950">Back</button>

        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Update Subportfolio</h2>

        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
        {successMessage && <p className="text-green-600 text-center">{successMessage}</p>}

        <Formik
          enableReinitialize
          initialValues={{
            title: subportfolioDetails?.title || '',
            subtitle: subportfolioDetails?.subtitle || '',
            description: subportfolioDetails?.description || '',
            images: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                <Field
                  id="title"
                  name="title"
                  placeholder="Enter title"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="title" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="subtitle" className="block text-sm font-medium text-gray-700">Subtitle</label>
                <Field
                  id="subtitle"
                  name="subtitle"
                  placeholder="Enter subtitle"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="subtitle" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="description" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="images" className="block text-sm font-medium text-gray-700">Upload Photos</label>
                <input
                  id="images"
                  name="images"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="images" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <h3 className="text-sm font-medium text-gray-700">Uploaded Files</h3>
                <div className="mt-2">
                  {files.length > 0 ? (
                    <ul>
                      {files.map((file, index) => (
                        <li key={index} className="flex justify-between items-center">
                          <span>{file.name}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveFile(index)}
                            className="text-red-600 hover:text-red-800"
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No files uploaded.</p>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="w-full mt-4 p-2 bg-blue-600 text-white font-semibold rounded-md"
              >
                Update Subportfolio
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateSubportfolio;
