import React, { useState } from 'react';
import { BASE_URL } from '../utils/Helper';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUser } from '../utils/redux/slices/useSlices';

const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const handleLogin = async (values) => {
        setLoading(true);
        setError(null);

        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(values),
                credentials: "include"
            };

            const res = await fetch(`${BASE_URL}login`, options);
            const json = await res.json()
            const data = json?.data
            console.log("data", data)
            localStorage.setItem("token", json?.token)
            dispatch(addUser(data))
            if (!res.ok) {
                throw new Error(`${data.message}`);
            }
            navigate("/dashbord", toast.success("Login Successfully!"))

        } catch (error) {
            // console.error("An error occurred:", error);
            setError(error.message);
            toast.error(error.message)
        } finally {
            setLoading(false);
        }
    };

    return { handleLogin, loading, error };
}

export default useLogin;
