import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'

const useAllBlogs = () => {
    const [blogData, setBlogData] = useState([])
    const [page, setPAge] = useState(1)

    const fetchBlogs = async () => {
        try {
            const res = await axios.get(BASE_URL + "blogs?limit=50", { withCredentials: true })
            const data = res?.data?.data
            console.log("data", data)
            if (data) {
                setBlogData(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchBlogs()
    }, [])

    return { blogData, page, setPAge }
}

export default useAllBlogs
