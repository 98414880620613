import React from 'react';
import AllServiceCarosul from './subcomponents/AllServiceCarosul';
import useGetAllService from '../customhooks/useGetAllService';
import Footer from './Footer';

const Services = () => {
    const { service, loading } = useGetAllService();

    if (loading) {
        return <h1 className="text-center text-lg sm:text-xl">Loading...</h1>;
    }

    return (
        <>
            <div className='px-4 sm:px-8 md:px-16 lg:px-24'>
                <div className='py-4'>
                    <h1 className='text-xl md:text-2xl lg:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] inline-block'>
                        Our Services.
                    </h1>
                    <p className='py-5 text-textmain text-base sm:text-lg md:text-lg'>
                        Wedding Cluster is one of the most trusted wedding brands in India. Wedding Cluster is one platform solution for all wedding services. We offer a diverse range of venues, best décor & catering services, etc.
                    </p>
                </div>
                <div className='bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] h-[500px] flex items-center justify-center'>
                    <h1 className='text-5xl md:text-6xl lg:text-7xl font-bold'>
                        Your Dream Wedding Awaits
                    </h1>
                </div>
                <h1 className='py-4 text-center text-2xl md:text-3xl bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882] font-semibold'>
                    DISCOVER YOUR UNIQUE PLACE TO SAY 'I DO'
                </h1>

                <div className="my-4">
                    {/* <hr className="border-t-2 border-[#1D2842] rounded-md shadow-sm" /> */}
                </div>
                <h1 className="text-3xl font-semibold text-gradient bg-clip-text bg-gradient-to-r from-[#1D2842] to-[#EBB882] border-b-2 border-[#EBB882] pb-3">Explore What We Provide</h1>
                <AllServiceCarosul service={service} />
            </div>
            <Footer />
        </>
    );
};

export default Services;
