import React from 'react';

const ConfirmDeleteModal = ({ isOpen, onClose, onDelete, serviceId }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg">
        <h3 className="text-xl font-semibold mb-4">Are you sure you want to delete this service?</h3>
        <div className="flex justify-around">
          <button 
            onClick={() => onDelete(serviceId)} 
            className="bg-red-600 text-white px-4 py-2 rounded-md"
          >
            Yes, Delete
          </button>
          <button 
            onClick={onClose} 
            className="bg-gray-300 px-4 py-2 rounded-md"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
