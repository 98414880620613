import React from 'react'
import { IMAGE_BASE_URL } from '../../utils/Helper'
import { MdOutlineArrowOutward } from 'react-icons/md'
import { Link } from 'react-router-dom'

const RealWeddings = ({ mainportfolio }) => {
    return (
        <div className='px-4 sm:px-8 md:px-16 '>
            <div className=''>
                <h1 className='text-xl md:text-2xl lg:text-3xl font-bold text-textmain'>Real Weddings</h1>
            </div>

            <div className='flex flex-col md:flex-row gap-2 justify-center mt-3'>
                {mainportfolio && mainportfolio.map((data) => (
                    data.image?.slice(0, 3).map((item, index) => (
                        <div key={index} className="w-full max-w-md relative hover:opacity-85">
                            <figure className='relative'>
                                <img
                                    className=' w-full'
                                    src={IMAGE_BASE_URL + item}
                                    alt="REAL Wedding image"
                                />
                                <div className='absolute inset-0 flex flex-col items-center justify-start'>
                                </div>
                            </figure>
                        </div>
                    ))
                ))}
            </div>


            <div className='flex flex-col md:flex-row gap-2 justify-center mt-2'>

                {mainportfolio && mainportfolio?.map((data) => (
                    data && data?.image.slice(3, 7).map((item) => (
                        <div className="w-80 max-w-md relative hover:opacity-85">
                            <figure className='relative'>
                                <img
                                    className=' w-full  '
                                    src={IMAGE_BASE_URL + item}
                                    alt="REAL Wedding"
                                />
                            </figure>
                        </div>
                    ))
                ))}

            </div>
            <div className='flex flex-col md:flex-row gap-2 justify-center mt-2'>
                {mainportfolio && mainportfolio.map((data) => (
                    data.image?.slice(7, 10).map((item, index) => (
                        <div key={index} className="w-full max-w-md relative hover:opacity-85">
                            <figure className='relative'>
                                <img
                                    className=' w-full'
                                    src={IMAGE_BASE_URL + item}
                                    alt="REAL Wedding image"
                                />
                                <div className='absolute inset-0 flex flex-col items-center justify-start'>
                                </div>
                            </figure>
                        </div>
                    ))
                ))}
            </div>
            <div className='my-2'>
                <div className='float-end px-2 py-2 rounded-lg transition-all duration-600 bg-gradient-to-r from-[#1D2842] to-[#EBB882] '>
                    <Link to={`/portfolio`}>
                        <h1 className='p-2 text-white px-4 text-sm sm:text-md border border-transparent flex items-center  hover:border hover:border-white rounded-lg hover:text-white'>
                            Explore more real Weddings
                            <MdOutlineArrowOutward className='' />
                        </h1>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default RealWeddings
