import React from 'react'

const TermsCondition = () => {
    return (
        <section className="bg-gray-100 p-6">
            <div className="container mx-auto px-4 py-8">

                <h4 className="text-2xl font-semibold text-gray-800 mb-4">Terms and Conditions</h4>

                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">Welcome to Wedding cluster!</p>
                    <p className="text-lg text-gray-700 mb-2">
                        These terms and conditions outline the rules and regulations for the use of the Wedding cluster Website, located at https://weddingcluster.com/
                    </p>
                    <p className="text-lg text-gray-700 mb-2">
                        By accessing this website we assume you accept these terms and conditions. Do not continue to use Wedding cluster if you do not agree to take all of the terms and conditions stated on this page. The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands.
                    </p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">Cookies</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">
                        We employ the use of cookies. By accessing the Wedding cluster, you agreed to use cookies in agreement with the Wedding cluster Privacy Policy.
                    </p>
                    <p className="text-lg text-gray-700">
                        Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                    </p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">License</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">
                        Unless otherwise stated, Wedding cluster and/or its licensors own the intellectual property rights for all material on Wedding cluster. All intellectual property rights are reserved. You may access this from Wedding cluster for your own personal use subjected to restrictions set in these terms and conditions.
                    </p>
                    <p className="text-lg text-gray-700 font-semibold mb-2">You must not:</p>
                    <ul className="list-disc pl-8 text-lg text-gray-700 mb-2">
                        <li>Republish material from Wedding cluster.</li>
                        <li>Sell, rent or sub-license material from Wedding cluster.</li>
                        <li>Reproduce, duplicate or copy material from Wedding cluster.</li>
                        <li>Redistribute content from Wedding cluster.</li>
                    </ul>
                    <p className="text-lg text-gray-700 mb-2">
                        This Agreement shall begin on the date hereof. Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Wedding cluster does not filter, edit, publish or review Comments prior to their presence on the website.
                    </p>
                    <p className="text-lg text-gray-700 mb-2">
                        Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Wedding cluster shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                    </p>
                    <p className="text-lg text-gray-700 mb-2">
                        Wedding cluster reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
                    </p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">Hyperlinking to our Content</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">The following organizations may link to our Website without prior written approval:</p>
                    <ul className="list-disc pl-8 text-lg text-gray-700 mb-2">
                        <li>Government agencies;</li>
                        <li>Search engines;</li>
                        <li>News organizations;</li>
                        <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses;</li>
                        <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                    </ul>
                    <p className="text-lg text-gray-700 mb-2">
                        These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.
                    </p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">Iframes</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">Content Liability</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">You’re Privacy</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">Please read Privacy Policy</p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">Reservation of Rights</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">Removal of links from our website</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly. We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
                </div>

                <h4 className="text-xl font-semibold text-gray-800 mb-2">Disclaimer</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website.</p>
                    <ul className="list-disc pl-8 text-lg text-gray-700 mb-2">
                        <li>limit or exclude our or your liability for death or personal injury;</li>
                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                        <li>limit any of our or your liabilities in any way that is not permitted under applicable law;</li>
                        <li>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                    </ul>
                    <p className="text-lg text-gray-700">The limitations and prohibitions of liability set in this section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, tort, and for breach of statutory duty.</p>
                </div>

            </div>
        </section>
    )
}

export default TermsCondition
