import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddService = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Service name must be at least 3 characters long.')
      .max(50, 'Service name must be no longer than 50 characters.')
      .required('Service name is required.'),
    description1: Yup.string().required('Description 1 is required.'),
    description2: Yup.string(),
    photos: Yup.mixed()
      .required('At least one photo is required.')
      .test('fileSize', 'File size must be less than 5MB each.', (value) => {
        return value && Array.from(value).every(file => file.size <= 1024 * 1024 * 5);
      })
      .test('fileType', 'Only JPEG, JPG, and PNG files are allowed.', (value) =>
        value && Array.from(value).every(file => ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type))
      ),
  });

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description1', values.description1);
      formData.append('description2', values.description2);
      
      if (values.photos) {
        Array.from(values.photos).forEach((file) => {
          formData.append('photos', file);
        });
      }

      const response = await fetch("http://localhost:3511/api/services/create-service", {
        method: "POST",
        headers: {
          "Accept": "application/json",
        },
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        setErrorMessage('Error creating the service.');
        return;
      }

      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Service created successfully!');
        setErrorMessage('');
        navigate('/services'); 
      } else {
        setErrorMessage(data.message || 'Failed to create service.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error creating service:', error);
      setErrorMessage('Error creating the service.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="flex justify-center mt-10">
      <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
        <button onClick={() => navigate(-1)} className="p-2 m-2 w-20 text-white rounded-lg bg-blue-950">Back</button>

        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Create Service</h2>

        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
        {successMessage && <p className="text-green-600 text-center">{successMessage}</p>}

        <Formik
          initialValues={{
            name: '',
            description1: '',
            description2: '',
            photos: null, 
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Service Name</label>
                <Field
                  id="name"
                  name="name"
                  placeholder="Enter service name"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="name" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description1" className="block text-sm font-medium text-gray-700">Description 1</label>
                <Field
                  as="textarea"
                  id="description1"
                  name="description1"
                  placeholder="Enter description 1"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="description1" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description2" className="block text-sm font-medium text-gray-700">Description 2 (optional)</label>
                <Field
                  as="textarea"
                  id="description2"
                  name="description2"
                  placeholder="Enter description 2"
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="description2" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="photos" className="block text-sm font-medium text-gray-700">Service Photos</label>
                <input
                  id="photos" 
                  name="photos" 
                  type="file"
                  onChange={(event) => setFieldValue('photos', event.currentTarget.files)}
                  multiple
                  className="mt-2 p-2 border w-full rounded-md"
                />
                <ErrorMessage name="photos" component="div" className="text-red-600 text-sm" />
              </div>

              <div className="mb-4 text-center">
                <button type="submit" className="bg-blue-950 text-white py-2 px-6 rounded-md hover:bg-blue-950">Create Service</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddService;
