import { Navigate } from "react-router-dom";
import Login from "../components/Login";
import Register from "../components/Register";

import CreateService from "../components/subcomponents/AdminCreate/createService";
import AddService from "../components/subcomponents/AdminCreate/AddService"
import UpdateService from "../components/subcomponents/AdminCreate/UpdateService";
import Subportfolio from "../components/subcomponents/AdminCreate/Subportfolio";
import UpdateSubportfolio from "../components/subcomponents/AdminCreate/UpdateSubportfolio";
import AddSubPortfolio from "../components/subcomponents/AdminCreate/AddsubPortfolio";
import Feedbacks from "../components/subcomponents/AdminCreate/Feedbacks";
import AddFeedback from "../components/subcomponents/AdminCreate/AddFeedback";
import UpdateFeedback from "../components/subcomponents/AdminCreate/UpdateFeedback";

const isAuthenticated = () => {
    return !!localStorage.getItem("token");
};


const AuthRoutes = [
    {
        path: "/login",
        element: isAuthenticated() ? <Navigate to="/" /> : <Login />,
    },
    {
        path: "/register",
        element: isAuthenticated() ? <Navigate to="/" /> : <Register />
    },

]

export default AuthRoutes;