import React from 'react'
import { Link } from 'react-router-dom'
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { IMAGE_BASE_URL } from '../../utils/Helper';

const FirstBlog = ({ blog }) => {
    // console.log(blog)

    if (!blog) return <h1>Loading...</h1>
    return (
        <div className="grid grid-rows-1 grid-cols-1 md:grid-cols-2 gap-8 border p-6 rounded-lg">

            <div className="p-4 md:p-6">
                <p className="text-sm text-gray-400 flex items-center gap-1">
                    <MdOutlineAccessTimeFilled />
                    <span>{new Date(blog?.date).toLocaleDateString('en-IN', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    })}</span>
                </p>
                <div className="py-3">
                    <h1 className="text-2xl font-bold text-[#1D2842]">{blog?.title}</h1>
                </div>
                <div className="py-3">
                    <p className="text-lg text-gray-700">{blog?.subtitle}</p>
                </div>
                <h1 className="text-base text-gray-600 mt-4">{blog?.content}</h1>
                <Link to={`/post/${blog?._id}`} className="text-blue-500 hover:underline mt-4 inline-block">
                    Read More
                </Link>
            </div>

            <div className="flex flex-col items-center justify-center h-full space-y-6 p-4">
                {blog?.images && blog.images.length > 0 && (
                    <div className="w-full md:w-10/12 lg:w-8/12 xl:w-6/12 mx-auto mt-4">
                        <img
                            src={IMAGE_BASE_URL + blog?.images[0]?.url}
                            alt="Blog image"
                            className="rounded-lg object-cover w-full max-w-lg h-64 md:h-80 lg:h-96 shadow-md"
                        />
                    </div>
                )}
            </div>
        </div>

    )
}

export default FirstBlog
