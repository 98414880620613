import React from 'react'

const Privacy = () => {
    return (
        <section className="privacy py-8 px-4">
            <div className="container mx-auto">

                <h4 className="text-3xl font-semibold mb-6">Privacy Policy for Wedding Cluster</h4>

                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">
                        One of our top priorities at Wedding Cluster, which can be found at https://weddingcluster.com/, is the privacy of our visitors. This Privacy Policy outlines the types of information that Wedding Cluster collects and records, as well as how we use it.
                    </p>
                    <p className="text-lg text-gray-700 mb-2">
                        Please do not hesitate to contact us if you have any further questions or need additional details about our Privacy Policy.
                    </p>
                    <p className="text-lg text-gray-700 mb-2">
                        This Privacy Policy only applies to our online activities and is applicable for visitors to our website who exchange and/or collect information with Wedding Cluster. This policy does not apply to any information gathered offline or by other means.
                    </p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">Acceptance</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700">Through using our website, you consent to and adhere to the terms of our Privacy Policy.</p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">Information We Collect</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">
                        The personal information you are asked to give, as well as the reasons for doing so, will be made clear to you at the time you are asked to provide your personal information.
                    </p>
                    <p className="text-lg text-gray-700 mb-2">
                        We may receive additional information about you if you contact us directly, such as your name, email address, phone number, the contents of any message and/or attachments you send us, and any other information you choose to provide.
                    </p>
                    <p className="text-lg text-gray-700">We may ask for your contact information when you register for an Account, such as your name, company name, address, email address, and phone number.</p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">How We Use Your Information</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">We use the information we collect in various ways, including:</p>
                    <ul className="list-disc pl-6 text-lg text-gray-700">
                        <li>Providing, operating, and maintaining our website.</li>
                        <li>Improving, personalizing, and expanding our website.</li>
                        <li>Recognizing and evaluating how you use our website.</li>
                        <li>Creating new products, services, features, and capabilities.</li>
                        <li>Communicating with you, either directly or through one of our partners, for customer service, updates, marketing, and promotional purposes.</li>
                        <li>Sending you emails.</li>
                        <li>Detecting and preventing fraud.</li>
                    </ul>
                </div>

                <h4 className="text-2xl font-semibold mb-4">Log Files</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">
                        Wedding Cluster uses log files in accordance with industry standards. When people visit websites, these files keep track of who they are. This is something that all hosting companies do as part of their analytics. Internet Protocol (IP) addresses, browser version, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks are among the data obtained by log files.
                    </p>
                    <p className="text-lg text-gray-700">These are not linked to any personally identifiable information. The information is collected for the purposes of analyzing patterns, running the site, monitoring users' movements on the site, and collecting demographic data.</p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">Cookies and Web Beacons</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700">
                        Online crawlers, like every other website, make use of ‘cookies.' These cookies are used to store information, including visitors’ interests and the pages on the website that the user accessed or visited. By customizing our web page content based on visitors' browser type and/or other details, we can improve the user experience.
                    </p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">Advertising Partners Privacy Policies</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">
                        Our consultants may consult this list to find the Privacy Policy for each of the advertising partners of Wedding Cluster.
                    </p>
                    <p className="text-lg text-gray-700 mb-2">
                        Third-party ad servers or ad networks use technology such as cookies, JavaScript, or Web Beacons in their ads and links on Wedding Cluster, and these are sent directly to users' browsers. When this happens, the IP address is immediately sent to them. These tools are used to assess the efficacy of their advertising campaigns and/or to tailor the advertising material you see on websites you visit.
                    </p>
                    <p className="text-lg text-gray-700">Wedding Cluster does not have access to or control over the cookies used by third-party advertisers.</p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">Third-Party Privacy Policies</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700 mb-2">
                        Other advertisers or websites are not covered by this Privacy Policy. As a result, we recommend that you read the Privacy Policies of these third-party ad servers for more details. It may include information about their activities and guidance on how to opt out of those choices.
                    </p>
                    <p className="text-lg text-gray-700">You can disable cookies in your browser by changing the settings. More information about cookie management for various web browsers can be found on the respective browsers' websites.</p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700">
                        Under the CCPA, among other protections, California customers have the right to:
                        <ul className="list-disc pl-6 text-lg text-gray-700">
                            <li>Request that a company disclose the categories and specific pieces of personal data it has collected about them.</li>
                            <li>Request that a company delete all personal data about them.</li>
                            <li>Request that a company not sell their personal data.</li>
                        </ul>
                        If you submit a request, you will receive a response within one month. Please contact us if you wish to exercise any of these rights.
                    </p>
                </div>

                <h4 className="text-2xl font-semibold mb-4">GDPR Data Protection Rights</h4>
                <div className="mb-4">
                    <p className="text-lg text-gray-700">
                        We want to make sure you understand your data privacy rights fully. Every consumer has the following rights:
                    </p>
                    <ul className="list-disc pl-6 text-lg text-gray-700">
                        <li>The right to access information – You have the right to request a copy of your personal information. We may charge a small fee for this service.</li>
                        <li>The right to be corrected – You have the right to request that we correct any information you believe is inaccurate.</li>
                        <li>The right to be forgotten – In certain circumstances, you have the right to request that we delete your personal information.</li>
                        <li>The right to limit processing – Under certain circumstances, you have the right to request that we restrict the processing of your personal data.</li>
                        <li>The right to object to processing – You have the right to object to our processing of your personal data under some circumstances.</li>
                        <li>The right to data portability – Under certain circumstances, you have the right to request that we transfer your data to another company or directly to you.</li>
                    </ul>
                    <p className="text-lg text-gray-700">
                        If you make a submission, we must respond within one month. If you wish to exercise any of these rights, please feel free to contact us.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Privacy
