import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper'

const useFetchSubPortfolio = () => {
    const [subPortFolio, setSubPortfolioData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchSubPortfolio = async () => {
        try {
            const res = await fetch(BASE_URL + "getall-subportfolio")
            const json = await res.json()
            const data = json?.data
            setSubPortfolioData(data)
            // console.log(data)
        } catch (error) {
            console.error(error)
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchSubPortfolio()
    }, [])

    return { subPortFolio, loading }

}

export default useFetchSubPortfolio
