import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL, IMAGE_BASE_URL } from '../utils/Helper';

const EditSlide = () => {
    const location = useLocation();
    const data = location?.state;
    const navigate = useNavigate();

    useEffect(() => {
        if (!data) {
            navigate('/slider');
        }
    }, [data, navigate]);

    const handleSubmit = async (values) => {
        const formData = new FormData();

        formData.append('title', values.title);
        formData.append('subTitle', values.subTitle);
        formData.append('description', values.description);

        if (values.images && values.images.length > 0) {
            values.images.forEach((image) => {
                formData.append('images', image);
            });
        } else {
            data.images.forEach((image) => {
                formData.append('images', image);
            });
        }

        try {
            const res = await axios.post(`${BASE_URL}editslider/${data._id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            });
            // console.log(res);

            navigate('/slider');
        } catch (error) {
            console.error('Error saving slide:', error);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    title: data?.title || '',
                    subTitle: data?.subTitle || '',
                    description: data?.description || '',
                    images: []
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form encType="multipart/form-data">
                        <div className="flex justify-start px-4 sm:px-0">
                            <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                                <div className="pb-3">
                                    <p className="text-2xl font-semibold text-gray-700 mb-2">Edit Slider</p>
                                </div>

                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Title</p>
                                    <Field
                                        type="text"
                                        name="title"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage name="title" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">SubTitle</p>
                                    <Field
                                        as="textarea"
                                        name="subTitle"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600 resize-none h-24"
                                    />
                                    <ErrorMessage name="subTitle" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Description</p>
                                    <Field
                                        as="textarea"
                                        name="description"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600 resize-none h-24"
                                    />
                                    <ErrorMessage name="description" component="div" className="text-red-500 text-sm mt-1" />
                                </div>
                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Existing Images</p>
                                    <div className="flex gap-2">
                                        {data?.images.map((image, index) => (
                                            <img
                                                key={index}
                                                src={`${IMAGE_BASE_URL}${image}`}
                                                alt={`Slide ${index + 1}`}
                                                className="w-24 h-24 object-cover rounded-lg"
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Image Upload Section */}
                                <div className="mb-4">
                                    <p className="text-lg text-gray-700 mb-2">Update Images</p>
                                    <input
                                        type="file"
                                        name="images"
                                        accept="image/*"
                                        multiple
                                        onChange={(e) => {
                                            const files = Array.from(e.target.files);
                                            setFieldValue('images', files);
                                        }}
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                    />
                                    <ErrorMessage name="images" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="flex py-3 gap-4">
                                    <Link to="/portfolio" className="btn text-white bg-red-500 px-4 py-2 rounded-lg hover:bg-red-800">
                                        Cancel
                                    </Link>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="btn text-white bg-blue-500 px-4 py-2 rounded-lg hover:bg-blue-600"
                                    >
                                        {isSubmitting ? 'Saving...' : 'Save Changes'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditSlide;
