import { useState, useEffect } from 'react';

const useCompanyDetails = () => {
    const [companyDetails, setCompanyDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                setLoading(true);
                const response = await fetch('http://localhost:3511/api/get-company-detail');
                
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                
                const data = await response.json();
                setCompanyDetails(data);
            } catch (err) {
                setError(err.message || 'Error fetching company details');
            } finally {
                setLoading(false);
            }
        };

        fetchCompanyDetails();
    }, []);

    return { companyDetails, loading, error };
};

export default useCompanyDetails;
