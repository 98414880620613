import React from 'react'
import useGetSlider from '../../customhooks/useGetSlider'
import { IMAGE_BASE_URL } from '../../utils/Helper'
import { Link } from 'react-router-dom'

const Slider = () => {
    const { data } = useGetSlider()
    console.log("data", data)
    if (!data) return <h1>Loading ....</h1>
    return (
        <div className="">
            {data && data.map((item, index) => (
                <div key={item._id} className=" p-6 mb-8">
                    <div className='flex justify-between mb-4'>
                        <h3 className="text-xl font-semibold ">{item.title}</h3>
                        <Link to={`/editslider/${item._id}`} state={item} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain rounded-xl'>
                            Edit
                        </Link>
                    </div>
                    <p className="sub-title text-lgtext-gray-600 mb-4">{item.subTitle}</p>
                    <p className="description text-smtext-gray-500 mb-6">{item.description}</p>
                    <div className="images grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {item.images.map((image, imgIndex) => (
                            <img
                                key={imgIndex}
                                src={IMAGE_BASE_URL + image}
                                alt={`slide-image-${imgIndex}`}
                                className="slide-image w-full h-auto object-cover rounded-lg shadow-md"
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>

    )
}
export default Slider
