import { useState, useEffect } from 'react';

const useFetchMainPortfolio = () => {
    const [portFoliImages, setPortFolioImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPortFolioImage = async () => {
            try {
                setLoading(true);
                const response = await fetch('http://localhost:3511/api/getAllPortfolioImages');

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();
                setPortFolioImage(data);
            } catch (err) {
                setError(err.message || 'Error fetching portfolio images');
            } finally {
                setLoading(false);
            }
        };

        fetchPortFolioImage();
    }, []);

    return { portFoliImages, loading, error };
};

export default useFetchMainPortfolio;
