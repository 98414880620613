import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../utils/Helper';
import axios from 'axios';

const useGetCompanyProfile = () => {

    const [data, setCompanyData] = useState([])
    const [loading, setLoading] = useState(true);

    const companyData = async () => {
        try {

            const res = await axios.get(BASE_URL + 'getcompany',
                { withCredentials: true })
            const data = res?.data?.data
            setCompanyData(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        companyData()
    }, [])
    return { data, loading }
}



export default useGetCompanyProfile
