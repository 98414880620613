import React from 'react'
import { IMAGE_BASE_URL } from '../../utils/Helper'
import { Link } from 'react-router-dom'
import { MdOutlineArrowOutward } from "react-icons/md";


const ServicesComponent = ({ servicesData }) => {
    // console.log("servicesData", servicesData)

    const truncateText = (description, length) => {
        if (description?.length > length - 1) {
            return description.slice(0, 80) + " ..."
        } else {
            return description
        }

    }
    return (
        <div className='px-4 sm:px-8 md:px-16'>
            <div className='py-4'>
                <h1 className='text-xl md:text-2xl lg:text-3xl font-bold text-textmain'>
                    Find every Service you need
                </h1>
            </div>
            <div className='flex flex-wrap justify-center sm:justify-between gap-6 mt-2'>
                {servicesData && servicesData.length > 0 ? (
                    <>
                        {servicesData.slice(0, 6).map((services) => (
                            <div
                                key={services.id}
                                className="card-compact bg-base-100 w-full sm:w-80 md:w-96 shadow-none hover:shadow-2xl border rounded-lg border-t-2 transition duration-300"
                            >
                                <figure className="relative">
                                    <img
                                        src={IMAGE_BASE_URL + services.photoUrl}
                                        alt="SERVICE IMAGE"
                                        className="w-full h-48 object-cover rounded-t-md"
                                    />
                                    <div className="card-body items-center text-center py-5">
                                        <h2 className="card-title m-0 bg-clip-text text-transparent bg-gradient-to-r from-[#1D2842] to-[#EBB882]">
                                            {services?.name}
                                        </h2>
                                        <p className="m-0">
                                            {truncateText(services?.description1, 20)}{' '}
                                            <Link className='text-sm text-gray-400'>read more</Link>
                                        </p>
                                    </div>
                                </figure>
                            </div>
                        ))}
                    </>
                ) : (
                    <h2 className="text-lg sm:text-xl text-center">No images available</h2>
                )}
            </div>
            <div className='my-2'>
                <div className='float-end px-2 py-2 rounded-xl transition-all duration-600 bg-gradient-to-r from-[#1D2842] to-[#EBB882] '>
                    <Link to={`/services`}>
                        <h1 className='p-2 text-white px-4 text-sm sm:text-md border border-transparent flex items-center  hover:border hover:border-white rounded-lg hover:text-white'>
                            Explore Services
                            <MdOutlineArrowOutward className='' />
                        </h1>
                    </Link>
                </div>
            </div>


        </div>

    )
}

export default ServicesComponent
