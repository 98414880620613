import React from 'react'
import useAllBlogs from '../customhooks/useAllBlogs'
import BlogCard from './BlogCard'
import Pageination from './subcomponents/Pageination'
import { Link } from 'react-router-dom'


const Blogs = () => {
    const { blogData, page, setPAge } = useAllBlogs()
    return (
        <div>
            <div className='flex justify-between'>
                <h1 className='text-2xl font-bold text-textmain'>All Blogs</h1>
                <Link to={`/newblog`} className='border bg-textmain text-white px-4 hover:bg-white hover:text-textmain rounded-xl'>
                    New Blog
                </Link>
            </div>

            {blogData && blogData?.length > 0 &&
                blogData.sort((a, b) => new Date(b.date) - new Date(a.date)).slice((page - 1) * 10, page * 10).map((blog, Index) => (
                    <div key={blog?._id}>
                        <BlogCard data={blog} />
                    </div>
                ))}
            {blogData?.length > 0 &&
                <Pageination blog={blogData} page={page} setPAge={setPAge} />
            }
        </div>
    )
}

export default Blogs
