import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BASE_URL } from "../utils/Helper";
import { useDispatch } from "react-redux";
import { addUser } from "../utils/redux/slices/useSlices";

const EditProfile = () => {
    const location = useLocation();
    const userData = location?.state;
    const dispatch = useDispatch()

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        emailId: Yup.string().email("Invalid email").required("Email is required"),
    });

    const handleOnSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("emailId", values.emailId);


            const response = await axios.put(
                BASE_URL + `update/${userData._id}`,
                formData,
                {
                    headers: { "Content-Type": "application/json", },
                    withCredentials: true,
                }
            );

            console.log("Update Response:", response.data?.data)
            dispatch(addUser(response?.data?.data))
            navigate("/userprofile");
        } catch (error) {
            console.error("Error updating profile:", error);
        }
        setSubmitting(false);
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md border p-6 rounded-lg shadow-md bg-white sm:max-w-lg lg:max-w-xl">
                <Formik
                    initialValues={{
                        firstName: userData?.firstName || "",
                        lastName: userData?.lastName || "",
                        emailId: userData?.emailId || "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleOnSubmit}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <div className="pb-3">
                                <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                                    Edit Profile
                                </h2>
                            </div>

                            <div className="mb-4">
                                <label className="block text-lg text-gray-700 mb-2">
                                    First Name
                                </label>
                                <Field
                                    type="text"
                                    name="firstName"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                />
                                <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="text-red-500 text-sm mt-1"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-lg text-gray-700 mb-2">
                                    Last Name
                                </label>
                                <Field
                                    type="text"
                                    name="lastName"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                />
                                <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="text-red-500 text-sm mt-1"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-lg text-gray-700 mb-2">Email</label>
                                <Field
                                    type="email"
                                    name="emailId"
                                    className="w-full border border-gray-300 rounded-lg px-3 py-2 outline-none text-gray-600"
                                />
                                <ErrorMessage
                                    name="emailId"
                                    component="div"
                                    className="text-red-500 text-sm mt-1"
                                />
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-blue-600 text-white rounded-lg px-3 py-2 font-semibold hover:bg-blue-700 transition duration-200"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Updating..." : "Update Profile"}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default EditProfile;
