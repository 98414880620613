import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import useFeedApi from '../customhooks/useFeedApi';
import SliderComponent from './subcomponents/SliderComponent';
import ServicesComponent from './subcomponents/ServicesComponent';
import ServiceCoursal from './subcomponents/ServiceCoursal';
import Steps from './subcomponents/Steps';
import RealWeddings from './subcomponents/RealWeddings';
import WhatWeAre from './subcomponents/WhatWeAre';
import BlogComponent from './subcomponents/BlogComponent';
import ClientSay from './subcomponents/ClientSay';
import GoogleMap from './subcomponents/GoogleMap';
import Footer from './Footer';


const Body = () => {
    const { feed, loading } = useFeedApi();
    const location = useLocation()
    // console.log("location", location)
    // console.log("feed", feed)
    const sliderData = feed?.data?.slider;
    const servicesData = feed?.data?.services
    const mainportfolio = feed?.data?.mainportfolio
    const blog = feed?.data?.Blog
    const feedBack = feed?.data?.feedback

    if (loading) {
        return <h1 className="text-center text-lg sm:text-xl">Loading...</h1>;
    }

    return (
        <div>
            <SliderComponent sliderData={sliderData} />
            <ServicesComponent servicesData={servicesData} />
            <div className='py-20 mt-20 overflow-x-auto bg-[#1D2842]'>
                <ServiceCoursal />
            </div>
            <WhatWeAre />
            <RealWeddings mainportfolio={mainportfolio} />
            <Steps />
            <BlogComponent blog={blog} />
            <ClientSay feedBack={feedBack} />
            <Footer />

            {/* <Outlet /> */}
        </div>
    );
};

export default Body;
